/* ======================================================================== */
/* 6. asideLogos */
/* ======================================================================== */
.aside-logos
  &.section_mt
    margin-top: 145px
  &.section_mb
    margin-bottom: 145px
.aside-logos__wrapper-logo
  margin-top: 15px
  margin-bottom: 15px
  +trans1
  opacity: .6
  &:hover
    opacity: 1

@media only screen and (max-width: $xxl)
  .aside-logos
    &.section_mt
      margin-top: 105px
    &.section_mb
      margin-bottom: 105px

@media only screen and (max-width: $xl)
  .aside-logos
    &.section_mt
      margin-top: 65px
    &.section_mb
      margin-bottom: 65px

@media only screen and (max-width: $sm)
  .aside-logos
    &.section_mt
      margin-top: 45px
    &.section_mb
      margin-bottom: 45px
