/* ======================================================================== */
/* 13. comments */
/* ======================================================================== */
.comments-area, .comment-respond
.comment-list
  +reset-ul
  li
    padding-top: 30px
    padding-bottom: 30px
  > li:not(:last-child)
    // border-bottom: 1px solid $borders
  ol
    +reset-ul
    padding-left: 4%
    li:last-child
      padding-bottom: 0
.comments-title, .comment-reply-title
  font-family: $font-secondary
  font-size: 30px
  font-weight: bold
  line-height: 1.3
  margin-top: 0
.comments-title
  margin-bottom: 1em
.comment-reply-title
  margin-top: 80px
  margin-bottom: 1.5em
.comment-meta
  display: flex
  justify-content: space-between
  align-items: center
.comment-meta__wrapper-author
  display: flex
  align-items: center
.comment-metadata
  margin-left: 25px
  b
    display: block
    a
      font-family: $font-secondary
      font-size: 15px
      line-height: 1.5
      font-weight: bold
      color: $black
  time
    display: block
    font-size: 13px
    color: $gray2
    margin-top: 5px
.comment-content
  padding-left: 107px
  margin-top: -1em
.comment-reply-link
  padding: 10px 20px !important
  color: $black !important
  &:hover
    color: $white !important

@media only screen and (max-width: $xl)
  .comments-title, .comment-reply-title
    font-size: 25px

@media only screen and (max-width: $sm)
  .comment-content
    margin-top: 0
    padding-left: 0
  .comment-metadata
    margin-left: 10px
  .comment-list li
    padding-top: 15px
    padding-bottom: 15px
  .comments-title, .comment-reply-title
    font-size: 22px
  .comment-reply-title
    margin-top: 40px
    margin-bottom: 15px
