/* ======================================================================== */
/* 57. sectionProcess */
/* ======================================================================== */
.section-process
	&.section_mb
		margin-bottom: 130px
.section-process__wrapper-item
	margin-bottom: 30px

@media only screen and (max-width: $xxl)
	.section-process
		&.section_mb
			margin-bottom: 90px

@media only screen and (max-width: $xl)
	.section-process
		&.section_mb
			margin-bottom: 70px

@media only screen and (max-width: $sm)
	.section-process
		&.section_mb
			margin-bottom: 50px
