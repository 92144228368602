/* ======================================================================== */
/* 44. section404 */
/* ======================================================================== */
.section-404__big
	font-size: 350px
	line-height: 0.9
	font-weight: bold
	font-family: $font-secondary
.section-404__header
	h1
		font-size: 46px
		font-weight: bold
	p
		font-size: 16px
.section-404__wrapper-button
	margin-top: 80px

@media only screen and (max-width: $xl)
	.section-404__big
		font-size: 262px
	.section-404__header
		h1
			font-size: 34px
	.section-404__wrapper-button
		margin-top: 40px

@media only screen and (max-width: $sm)
	.section-404__big
		font-size: 130px
		margin-bottom: 20px
	.section-404__header
		h1
			font-size: 24px
	.section-404__wrapper-button
		margin-top: 30px
