/* ======================================================================== */
/* 9. avatar */
/* ======================================================================== */
.avatar
  width: 100%
  max-width: 82px
  max-height: 82px
  border-radius: 100%

@media only screen and (max-width: $sm)
  .avatar
    max-width: 60px
    max-height: 60px
