/* ======================================================================== */
/* 17. figureContact */
/* ======================================================================== */
.figure-contact
  .figure-contact__item:last-of-type
    margin-bottom: 0
.figure-contact__item
  margin-top: 10px
  margin-bottom: 40px
.figure-contact__property
  display: block
  font-size: 26px
  line-height: 1.3
.figure-contact__value
  display: block
  font-size: 13px
  margin-top: 8px
