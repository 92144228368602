/* ======================================================================== */
/* 64. slider */
/* ======================================================================== */
.slider
.slider-nav
  display: flex
  justify-content: center
  align-items: center
  margin-left: -3px
.slider-nav__dot
  position: relative
  width: 16px
  height: 16px
  cursor: pointer
  &:after
    content: ''
    display: block
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 7px
    height: 7px
    border-style: solid
    border-width: 3.5px
    border-color: $white
    border-radius: 50%
    +trans1
  &:not(.slider-nav__dot_active):hover
    &:after
      opacity: .5
.slider-nav__dot_active
  &:after
    transform: translate(-50%, -50%) scale(1.5714)
    border-width: 1px
.slider__prev, .slider__next
  display: flex
  align-items: center
  justify-content: center
  width: 52px
  height: 52px
  border: 2px solid $white
  border-radius: 50%
  cursor: pointer
  color: $white
  +trans1
  &:after
    font-family: 'ElegantIcons'
    speak: none
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 23px
  &:hover
    background-color: $accent
    border-color: $accent
    color: $white
  &:focus
    outline: none
  &.swiper-button-disabled
    opacity: 0
    visibility: hidden
.slider__prev
  &:after
    content: '\23'
.slider__next
  &:after
    content: '\24'

@media only screen and (max-width: $xl)
  .slider__prev, .slider__next
    width: 40px
    height: 40px
    &:after
      font-size: 16px
@media only screen and (max-width: $sm)
  .slider__prev, .slider__next
    display: none
