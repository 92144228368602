/* ======================================================================== */
/* 22. figureProcess */
/* ======================================================================== */
.figure-process
  position: relative
  text-align: center
  box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.1)
  background-color: $white
  padding: 40px 30px 40px
  +trans1
  height: 100%
  h4
    font-size: 18px
    margin-top: 1.5em
    margin-bottom: 0
  p
    margin-bottom: 0
  &:hover
    box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.2)
    transform: translateY(-5px)
.figure-process__wrapper-icon
  height: 120px
  img
    width: auto
    height: 100%
.figure-process__counter
  position: absolute
  left: 20px
  bottom: 15px
  font-size: 13px
  font-weight: bold
