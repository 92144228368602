/* ======================================================================== */
/* 73. social */
/* ======================================================================== */
.social
  +reset-ul
.social__item
  display: inline-block
  margin: 0 6px
.social__icon
  display: flex !important
  align-items: center
  justify-content: center
  width: 50px !important
  height: 50px !important
  font-size: 18px
  color: $white
  background-color: $gray2
  border-radius: 100%
  &:hover
    color: $white
    background-color: $accent

@media only screen and (max-width: $xl)
  .social__icon
    width: 40px !important
    height: 40px !important
    font-size: 14px
