/* ======================================================================== */
/* 65. sliderGallery */
/* ======================================================================== */
.slider-gallery__wrapper-slider-nav
  position: absolute
  bottom: 12px
  left: 50%
  transform: translateX(-50%)
  z-index: 60
.slider-gallery__overlay
  z-index: 50

@media only screen and (max-width: $md)
  .slider-gallery__overlay
    display: none
