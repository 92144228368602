/* ======================================================================== */
/* 33. menu */
/* ======================================================================== */
.menu
  +reset-ul
  width: 100%
  > li
    position: relative
    display: inline-block
    margin-right: 42px
    > a
      display: block
      color: $dark
      font-size: 18px
      font-weight: bold
      letter-spacing: 0.1px
      line-height: 2
      &:hover
        color: $dark
  > li:last-child
    margin-right: 0
  > li.menu-item-has-children
    > a
      &:after
        content: '\f107'
        font-family: 'FontAwesome'
        font-size: 14px
        display: inline-block
        vertical-align: middle
        margin-left: 8px
      &:hover
        ~ ul
          opacity: 1
          visibility: visible
          transform: translate(0, 0)
  > li:not(.menu-item-has-children)
    > a
      &:before
        content: ''
        position: absolute
        width: 0
        height: 1px
        background-color: $dark
        bottom: 0
        right: 1px
        +trans1
      &:hover
        color: $dark
        &:before
          left: 1px
          width: calc(100% - 1px)
.menu .sub-menu
  position: absolute
  top: calc(100% + 10px)
  left: 0
  +reset-ul
  padding: 10px 0
  opacity: 0
  visibility: hidden
  +trans1
  transform: translate(0, 10px)
  background-color: $white
  border-radius: 4px
  box-shadow: 0px 3px 14px 0px rgba(162, 162, 162, 0.65)
  z-index: 50
  > li
    position: relative
    display: block
    white-space: nowrap
    a
      display: flex
      align-items: center
      padding: 10px 30px
      color: $dark
      &:hover
        color: $accent
  > li.menu-item-has-children
    > a
      &:after
        content: '\f105'
        font-family: 'FontAwesome'
        font-size: 14px
        display: inline-block
        vertical-align: middle
        margin-left: auto
        margin-top: 2px
        padding-left: 20px
      &:hover
        ~ ul
          opacity: 1
          visibility: visible
          transform: translate(0, 0)
  ul
    top: -10px
    left: calc(100% - 10px)
    transform: translate(10px, 0)
    width: 100%
  &:hover
    opacity: 1
    visibility: visible
    transform: translate(0, 0)
