/* ======================================================================== */
/* 66. sliderImages */
/* ======================================================================== */
.slider-images
  text-align: center
  position: relative
.slider-images__wrapper-slider-nav
  position: absolute
  bottom: 12px
  left: 50%
  transform: translateX(-50%)
  z-index: 60
.slider-images__slide
  display: flex
  align-items: center
  justify-content: center
.slider-images__prev, .slider-images__next
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 60
.slider-images__prev
  left: 50px
.slider-images__next
  right: 50px

@media only screen and (max-width: $xl)
  .slider-images__prev
    left: 30px
  .slider-images__next
    right: 30px
