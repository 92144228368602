/* ======================================================================== */
/* 16. counter */
/* ======================================================================== */
.counter
	text-align: center
.counter__number
	font-size: 46px
	font-weight: bold
	font-family: $font-secondary
.counter__label
	margin-top: 5px

@media only screen and (max-width: $xl)
	.counter__number
