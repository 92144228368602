/* ======================================================================== */
/* 42. scrollUp */
/* ======================================================================== */
.scroll-up
	position: fixed
	right: 50px
	bottom: 50px
	z-index: 500
.scroll-up__icon
	display: inline-flex
	justify-content: center
	align-items: center
	cursor: pointer
	width: 60px
	height: 60px
	background-color: $accent
	border-radius: 100%
	color: $white
	font-size: 24px
	+trans1
	&:hover
		background-color: $dark

@media only screen and (max-width: $md)
	.scroll-up
		width: 50px
		height: 50px
		right: 30px
		bottom: 30px
		font-size: 20px
