/* ======================================================================== */
/* 47. sectionCTA */
/* ======================================================================== */
.section-cta
  h2
    margin-top: 0
.section-cta__wrapper-button
  margin-top: 40px


@media only screen and (max-width: $xl)
  .section-cta
    h2
      font-size: 45px
      
@media only screen and (max-width: $sm)
  .section-cta
    h2
      font-size: 33px
  .section-cta__wrapper-button
    margin-top: 30px
