/* ======================================================================== */
/* 51. sectionLatestNews */
/* ======================================================================== */
.section-latest-news
	&.section_pb
		padding-bottom: 130px

@media only screen and (max-width: $xxl)
	.section-latest-news
		&.section_pb
			padding-bottom: 100px

@media only screen and (max-width: $xl)
	.section-latest-news
		&.section_pb
			padding-bottom: 50px

@media only screen and (max-width: $sm)
	.section-latest-news
		&.section_pb
			padding-bottom: 40px
