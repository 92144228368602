/* ======================================================================== */
/* 39. preloader */
/* ======================================================================== */
.preloader
	display: flex
	align-items: center
	justify-content: center
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 999
	overflow: hidden
.preloader__curtain
	position: absolute
	top: 0
	left: 100%
	width: 3000px
	height: 3000px
	transform: skew(45deg)
	transform-origin: bottom right
	&:nth-child(1)
		z-index: 0
	&:nth-child(2)
		z-index: 1
	&:nth-child(3)
		z-index: 2
.preloader__progress
	position: relative
	width: 100%
	height: 2px
	background-color: $dark
	margin-left: auto
	margin-right: auto
	max-width: 320px
	z-index: 50
.preloader__progress-fill
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: $white
	transform: scaleX(0)
	transform-origin: left center

@media only screen and (max-width: $sm)
	.preloader__progress
		max-width: 270px
