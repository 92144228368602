/* ======================================================================== */
/* 35. ofi */
/* ======================================================================== */
.of-cover
  width: 100% !important
  height: 100% !important
  object-fit: cover
  font-family: 'object-fit: cover;'
.of-contain
  width: 100% !important
  height: 100% !important
  object-fit: contain
  font-family: 'object-fit: contain;'
