/* ======================================================================== */
/* 20. figurePost */
/* ======================================================================== */
.figure-post
  position: relative
  display: flex
  flex-direction: column
  height: 100%
  // background: $white
  padding-bottom: 30px
  z-index: 50
  &.bg-accent
    color: $white
    .post-meta__link
      color: $white
      &:hover
        color: $gray
.figure-post_gallery
  padding-bottom: 0
  .figure-post__wrapper-content h3 a, .post-meta__item, .post-meta__item-text
    color: $white
  p
    color: $white
  .post-meta__link
    color: $white
  .figure-post__inner
    position: absolute
    left: 0
    bottom: 50px
    height: auto
.figure-post_quote
  padding: 30px 0
  blockquote
    font-size: 20px
    line-height: 1.5
    cite
      display: block
      font-family: $font-secondary
      font-size: 15px
      font-weight: bold
      font-style: normal
      margin-top: 20px
  // .figure-post__inner
  //   height: 100%
  .figure-post__footer
    margin-top: 0
  &.bg-accent
    .figure-post__wrapper-quote, .post-meta__item, .post-meta__item-text
      color: $white
.figure-post__sign
  font-size: 32px
  text-align: right
  padding: 0 30px
  // position: absolute
  // right: 0
  // bottom: 0
.figure-post__wrapper-quote
  padding: 0 30px
  margin-top: auto
  margin-bottom: auto
.figure-post__inner
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  
.figure-post_shadow
  box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.1)
  +trans1
  &:hover
    box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.2)
    transform: translateY(-5px)
.figure-post[class*='bg-']
  .figure-post__wrapper-content
    position: relative
    padding: 0 30px
  .figure-post__footer
    padding: 0 30px
.figure-post__wrapper-thumbnail
  display: block
  // margin-bottom: 25px
  img
    width: 100%
.figure-post__wrapper-content
  position: relative
  z-index: 50
  h3
    font-family: $font-secondary
    font-size: 21px
    line-height: 1.42857
    font-weight: bold
    margin-top: 25px
    a
      color: $black
      &:hover
        color: $accent
.figure-post__footer
  position: relative
  z-index: 50
  margin-top: auto

@media only screen and (max-width: $md)
  .figure-post_gallery .figure-post__inner
    position: relative
    left: auto
    bottom: auto
  .figure-post_gallery
    padding-bottom: 30px
    .figure-post__wrapper-content h3 a,
    .post-meta__item,
    .post-meta__item-text,
    .post-meta__link,
    p
      color: $black
  .figure-post_quote blockquote
    margin-top: 0em
    margin-bottom: 0em
    

