/* ======================================================================== */
/* 68. sliderIntroContent */
/* ======================================================================== */
.slider-intro-content
.slider-intro-content__wrapper-button
  margin-top: 90px
  overflow: hidden
.slider-intro-content__wrapper-slider-nav
  position: absolute
  left: 215px
  bottom: 30px
  z-index: 60

@media only screen and (max-width: $xxl)
  .slider-intro-content__wrapper-button
    margin-top: 70px
  .slider-intro-content__wrapper-slider-nav
    left: 50px

@media only screen and (max-width: $xl)
  .slider-intro-content__wrapper-slider-nav
    left: 30px

@media only screen and (max-width: $md)
  .slider-intro-content__wrapper-slider-nav
    bottom: 15px

@media only screen and (max-width: $sm)
  .slider-intro-content__wrapper-button
    margin-top: 50px
  