/* ======================================================================== */
/* 18. figureFeature */
/* ======================================================================== */
.figure-feature
	max-width: 300px
.figure-feature__icon
	font-size: 30px
	color: $accent
.figure-feature__content
	h3
		font-size: 18px
		font-weight: bold
		font-family: $font-secondary
		margin-top: 20px
		margin-bottom: 0
	p
		margin-top: 10px
		margin-bottom: 0

@media only screen and (max-width: $xs)
	.figure-feature
		max-width: 100%
