/* ======================================================================== */
/* 43. section */
/* ======================================================================== */
.section
  position: relative
.section_pt
  padding-top: 160px
.section_pt-small
  padding-top: 70px
.section_pt-large
  padding-top: 200px
.section_pb
  padding-bottom: 160px
.section_pb-small
  padding-bottom: 70px
.section_pb-large
  padding-bottom: 200px
.section_mt
  margin-top: 160px
.section_mt-small
  margin-top: 70px
.section_mt-large
  margin-top: 200px
.section_mb
  margin-bottom: 160px
.section_mb-small
  margin-bottom: 70px
.section_mb-large
  margin-bottom: 200px
.section-fullheight
  display: flex
.section-fullheight__inner
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  min-height: 100vh
.section__header
  position: relative
  margin-bottom: 60px
  z-index: 50
  h2
    font-family: $font-secondary
    font-size: 46px
    margin-top: 0
    margin-bottom: 0.5em
    line-height: 1.3
  p
    margin-bottom: 0
.section__content
  position: relative
  z-index: 50


@media only screen and (max-width: $xxl)
  //
  .section_pt
    padding-top: 120px
  .section_pt-small
    padding-top: 50px
  .section_pt-large
    padding-top: 160px
  .section_pb
    padding-bottom: 120px
  .section_pb-small
    padding-bottom: 50px
  .section_pb-large
    padding-bottom: 160px
  .section_mt
    margin-top: 120px
  .section_mt-small
    margin-top: 50px
  .section_mt-large
    margin-top: 160px
  .section_mb
    margin-bottom: 120px
  .section_mb-small
    margin-bottom: 50px
  .section_mb-large
    margin-bottom: 160px
  //
  .section__header
    margin-bottom: 40px
    h2
      font-size: 34px

@media only screen and (max-width: $xl)
  //
  .section_pt
    padding-top: 80px
  .section_pt-small
    padding-top: 40px
  .section_pt-large
    padding-top: 100px
  .section_pb
    padding-bottom: 80px
  .section_pb-small
    padding-bottom: 40px
  .section_pb-large
    padding-bottom: 100px
  .section_mt
    margin-top: 80px
  .section_mt-small
    margin-top: 40px
  .section_mt-large
    margin-top: 100px
  .section_mb
    margin-bottom: 80px
  .section_mb-small
    margin-bottom: 40px
  .section_mb-large
    margin-bottom: 100px

@media only screen and (max-width: $sm)
  //
  .section_pt
    padding-top: 60px
  .section_pt-small
    padding-top: 30px
  .section_pt-large
    padding-top: 80px
  .section_pb
    padding-bottom: 60px
  .section_pb-small
    padding-bottom: 30px
  .section_pb-large
    padding-bottom: 80px
  .section_mt
    margin-top: 60px
  .section_mt-small
    margin-top: 30px
  .section_mt-large
    margin-top: 80px
  .section_mb
    margin-bottom: 60px
  .section_mb-small
    margin-bottom: 30px
  .section_mb-large
    margin-bottom: 80px
