/* ======================================================================== */
/* 48. sectionContacts */
/* ======================================================================== */
.section-contacts
	&.color-white
		.figure-contact
			a
				color: $white
				&:hover
					color: $white
					opacity: .5
@media only screen and (max-width: $md)
	.section-contacts__wrapper-contacts
		margin-top: 40px
