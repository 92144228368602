/* ======================================================================== */
/* 50. sectionIntro */
/* ======================================================================== */
.section-intro
  overflow: hidden
  h1
    font-family: $font-secondary
    font-size: 60px
    line-height: 1.2
    font-weight: bold
    letter-spacing: 0.5px
    margin-top: 0
    margin-bottom: 0.5em
  p
    font-size: 20px
.section-intro__wrapper-content
  padding-left: 215px
  padding-top: 160px
  padding-bottom: 160px
.section-intro__wrapper-backgrounds
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: -1
.section-intro__wrapper-sd
  position: absolute
  display: inline-block
  bottom: 18px
  left: 38%
  z-index: 60

.section-intro__diagonal-curtain-1, .section-intro__diagonal-curtain-2
  position: absolute
  width: 3000px
  height: 3000px
.section-intro__diagonal-curtain-1
  top: 100%
  // right: 200px
  right: 20%
  transform: rotate(45deg)
  transform-origin: top right
  background-color: $accent
.section-intro__diagonal-curtain-2
  bottom: 100%
  // right: 200px
  right: 20%
  transform: rotate(-45deg)
  transform-origin: bottom right
  background-color: $accent
.section-intro__diagonal-line-1, .section-intro__diagonal-line-2
  position: absolute
  right: calc(20% - 32px)
  height: 10px
  width: 3000px
  background-color: $accent
.section-intro__diagonal-line-1
  bottom: calc(100% + 10px)
  transform: rotate(-45deg)
  transform-origin: top right
.section-intro__diagonal-line-2
  top: calc(100% + 10px)
  transform: rotate(45deg)
  transform-origin: bottom right


// $i: 500
// @while $i < 1700
//   $bp: $i
//   $val: 74.9 - $i / 1666
//   $i: $i + 100
//   @media only screen and (max-height: #{$bp}px)
//     .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//       width: calc(#{$val}vh)    
// @media only screen and (max-height: 1700px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.1vh)
// @media only screen and (max-height: 1600px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.3vh)
// @media only screen and (max-height: 1500px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.5vh)
// @media only screen and (max-height: 1400px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.7vh)
// @media only screen and (max-height: 1300px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.9vh)
// @media only screen and (max-height: 1200px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.1vh)
// @media only screen and (max-height: 1100px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.3vh)
// @media only screen and (max-height: 1000px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.6vh)
// @media only screen and (max-height: 900px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.9vh)
// @media only screen and (max-height: 800px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.2vh)
// @media only screen and (max-height: 700px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.5vh)
// @media only screen and (max-height: 600px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.9vh)
// @media only screen and (max-height: 500px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(75.3vh)

@media only screen and (max-width: $xxl)
  .section-intro
    h1
      font-size: 46px
    p
      font-size: 18px
  .section-intro__wrapper-content
    padding-left: 50px

@media only screen and (max-width: $md)
  .section-intro__wrapper-content
    padding-left: 15px
    padding-right: 15px
    text-align: center
  .section-intro__wrapper-sd
    left: 50%
    transform: translateX(-50%)
@media only screen and (max-width: $sm)
  .section-intro
    h1
      font-size: 30px
    p
      font-size: 16px
