/* ======================================================================== */
/* 59. sectionServicesTabs */
/* ======================================================================== */
.section-services-tabs
	z-index: 100
.section-services-tabs__wrapper-tabs
	margin-top: -160px
	margin-bottom: 60px
	padding-top: 100px
	padding-bottom: 100px
.section-services-tabs__content

@media only screen and (max-width: $xxl)
	.section-services-tabs__wrapper-tabs
		padding-top: 80px
		padding-bottom: 80px
		margin-top: -140px

@media only screen and (max-width: $xl)
	.section-services-tabs__wrapper-tabs
		padding-top: 60px
		padding-bottom: 60px
		margin-top: -120px
		margin-bottom: 40px

@media only screen and (max-width: $md)
	.section-services-tabs__wrapper-tabs
		margin-bottom: 30px

@media only screen and (max-width: $sm)
	.section-services-tabs__wrapper-tabs
		padding-top: 25px
		padding-bottom: 25px
		margin-top: 0
		max-width: 100%

@media only screen and (min-width: 720px)
	.section-services-tabs__wrapper-tabs
		max-width: 690px
@media only screen and (min-width: 992px)
	.section-services-tabs__wrapper-tabs
		max-width: 930px
@media only screen and (min-width: 1200px)
	.section-services-tabs__wrapper-tabs
		max-width: 1110px
