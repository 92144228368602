/* ======================================================================== */
/* 58. sectionServices */
/* ======================================================================== */
.section-services
.section-services__wrapper-service:last-of-type
  box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0)
.section-services__wrapper-service
  // border-right: 1px solid rgba(255, 255, 255, .2)
  +trans1
  box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0.1)
  &:hover
    box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0.62), 1px 0px 0px 0px rgba(255, 255, 255, 0)
    .section-services__service-overlay
      opacity: .85
.section-services__service-overlay
  +trans1
.section-services__wrapper-background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden
.section-services__background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

@media only screen and (max-width: $xl)
  .section-services__wrapper-service
    box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 0px 0px rgba(255, 255, 255, 0.1)
  