/* ======================================================================== */
/* 32. logo */
/* ======================================================================== */
.logo
	display: block
	height: 110px
	img
		width: auto
		height: 100%

@media only screen and (max-width: $xl)
	.logo
		height: 80px

