/* ======================================================================== */
/* 10. backgrounds */
/* ======================================================================== */
.bg-light
  background-color: $light
.bg-dark
  background-color: $dark
.bg-black
  background-color: $black
.bg-black-2
  background-color: $black2
.bg-white
  background-color: $white
.bg-accent
  background-color: $accent
.bg-fixed
  background-attachment: fixed
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
