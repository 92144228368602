/* ======================================================================== */
/* 60. sectionTeam */
/* ======================================================================== */
.section-team
	&.section_mb
		margin-bottom: 100px
.section-team__wrapper-item
	margin-bottom: 60px

@media only screen and (max-width: $xl)
	.section-team
		&.section_mb
			margin-bottom: 60px
	.section-team__wrapper-item
		margin-bottom: 40px
@media only screen and (max-width: $sm)
	.section-team
		&.section_mb
			margin-bottom: 20px
