/* ======================================================================== */
/* 77. utilities */
/* ======================================================================== */
.text-left
  text-align: left !important
.text-center
  text-align: center !important
.text-right
  text-align: right !important
.line
.row-80
  margin-left: -40px
  margin-right: -40px
.overflow
  position: relative
  overflow: hidden
.overflow__curtain
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 50
.overflow__content
  width: 100%
  height: 100%
.position-relative
  position: relative
.color-white
  color: $white !important
.color-accent
  color: $accent !important
.color-black
  color: $black !important
.color-dark
  color: $dark !important
.blurred
  filter: blur(7px)
.of-cover
  width: 100%
  height: 100%
  object-fit: cover
  font-family: 'object-fit: cover;'
.of-contain
  width: 100%
  height: 100%
  object-fit: contain
  font-family: 'object-fit: contain;'
.split-line
  overflow: hidden
.split-word
.split-char
.elegant-icons
  font-family: 'ElegantIcons'
  speak: none
  font-weight: normal
  font-variant: normal
  text-transform: none
  line-height: 1
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
.hidden
  opacity: 0
  visibility: hidden
