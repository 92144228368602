/* ======================================================================== */
/* 28. gmap */
/* ======================================================================== */
.gmap
  width: 100%
  height: 100%
  background-color: $light
.gmap__container
  width: 100%
  height: 100%
  min-height: 900px

@media only screen and (max-width: $md)
  .gmap__container
    min-height: 600px
