/* ======================================================================== */
/* 7. asideNextPost */
/* ======================================================================== */
.aside-next-post
  padding: 90px 0
  h3
    font-family: $font-secondary
    font-size: 30px
    margin-top: 10px
    margin-bottom: 10px
.aside-next-post__subheading
  display: block
  font-size: 13px
  font-weight: bold
  text-transform: uppercase
  letter-spacing: 1px
  margin-top: 10px
.aside-next-post__content
  color: $white
  display: block
