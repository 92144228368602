/* ======================================================================== */
/* 36. overlay */
/* ======================================================================== */
.overlay
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  opacity: 0
  z-index: -1
  will-change: transform
.overlay_dark
  background-color: $dark
  opacity: .65
  mix-blend-mode: multiply
.overlay_black
  background-color: $black
  opacity: .65
.overlay_white
  background-color: $white
  opacity: .65
.overlay_white-solid
  background-color: $white
  opacity: 1
.overlay_accent
  background-color: $accent
  opacity: 0.95
  mix-blend-mode: multiply
.overlay_accent-normal
  background-color: $accent
  opacity: .75
