/* ======================================================================== */
/* 74. tabs */
/* ======================================================================== */
.tabs
  text-align: center
  position: relative
  font-family: $font-secondary
  padding-bottom: 15px
.tabs__header
  margin-bottom: 60px
  h2
    margin-top: 0
    margin-bottom: 0
    font-size: 18px
    line-height: 1.5
    font-weight: bold
    font-family: $font-secondary
    letter-spacing: 1px
.tabs__item
  cursor: pointer
  color: $gray2
  +trans1
  h4
    margin-top: 0
    margin-bottom: 0
    display: inline-block
    font-size: 16px
    font-weight: bold
  &:hover
    color: inherit
.tabs__item_active
  color: inherit
  .tabs__icon
    color: $accent
    +trans1
.tabs__icon
  font-size: 50px
  margin-bottom: 5px
.tabs__underline
  position: absolute
  bottom: 0
  left: 0
  display: block
  background-color: $white
  width: 0
  height: 1px

@media only screen and (max-width: $md)
  .tabs__underline
    display: none

@media only screen and (max-width: $sm)
  .tabs__item
    margin-top: 15px
    margin-bottom: 15px
  .tabs__header
    margin-bottom: 30px
