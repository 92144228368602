/* ======================================================================== */
/* 12. button */
/* ======================================================================== */
.button
  position: relative
  display: inline-block
  padding: 16px 40px
  font-size: 13px
  font-weight: bold
  text-transform: uppercase
  border-radius: 25px
  line-height: 1
  letter-spacing: 0.5px
  text-align: center
  cursor: pointer
  color: $accent
  border-width: 2px
  border-style: solid
  border-color: transparent
  background-color: transparent
  +trans1
  &:focus
    outline: none
.button_arrow
  padding: 0
  &.button_white
    color: $white
    .button__text_line-right
      &:after
        background-color: $white
        +trans1
  &:hover
    .button__text_line-right
      &:after
        transform: translateX(5px)
    .button__icon
      transform: translateX(10px)
.button__icon
  +trans1
  display: inline-block
.button_bordered
  &.button_accent
    border-color: $accent
    color: $accent
    &:focus
      color: $accent
    &:hover
      color: $white
      background-color: $accent
  &.button_dark
    border-color: $dark
    color: $dark
    &:focus
      color: $dark
    &:hover
      color: $white
  &.button_light
    border-color: $light
    color: $light
    &:focus
      color: $light
    &:hover
      color: $dark
  &.button_black
    border-color: #000
    color: #000
    &:focus
      color: #000
    &:hover
      color: $white
  &.button_white
    border-color: $white
    color: $white
    &:focus
      color: $white
    &:hover
      color: $dark
      background-color: $white
.button_solid
  &.button_accent
    background-color: $accent
    color: $white
    &:focus
      color: $white
    &:hover
      border-color: $dark
      background-color: $dark
    &.button_shadow
      box-shadow: 0px 0px 30px 0px rgba(var(--color-accent-primary-rgb), 0.5)
      &:hover
        box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.5)
  &.button_dark
    background-color: $dark
    color: $white
    &:focus
      color: $white
    &:hover
      border-color: $dark
    &.button_shadow
      box-shadow: 0px 0px 30px 0px rgba(26, 26, 26, 0.2)
  &.button_light
    background-color: $light
    color: $dark
    &:focus
      color: $dark
    &:hover
      border-color: $light
    &.button_shadow
      box-shadow: 0px 0px 30px 0px rgba(245, 245, 245, 0.2)
  &.button_black
    background-color: $black
    color: $white
    &:focus
      color: $white
    &:hover
      border-color: $dark
    &.button_shadow
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2)
  &.button_white
    background-color: $white
    color: $accent
    &:focus
      color: $accent
    &:hover
      border-color: $dark
      background-color: $dark
      color: $white
    &.button_shadow
      box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2)
.button_fullwidth
  width: 100%
.button_circle
  display: inline-flex
  align-items: center
  justify-content: center
  font-size: 23px
  width: 52px
  height: 52px
  border-radius: 100%
  padding: 0
.button__text
.button__text_line
.button__text_line-right
  &:after
    content: ''
    display: inline-block
    width: 43px
    height: 1px
    vertical-align: middle
    margin-left: 12px
    margin-right: -14px

@media only screen and (max-width: $xl)
  .button:not(.button_arrow, .button_circle)
    padding: 14px 35px
    font-size: 12px

@media only screen and (max-width: $md)
  .button__icon
    max-height: 30px

