/* ======================================================================== */
/* 34. menuOverlay */
/* ======================================================================== */
.menu-overlay
	position: relative
	margin-top: auto
	margin-bottom: auto
	list-style-type: none
	text-align: center
	z-index: 50
	> li
		display: block
		margin: 20px 0
		> a
			position: relative
			display: block
			overflow: hidden
			color: $white
			font-size: 40px
			font-weight: bold
			&:hover
				color: $gray
	> li:not(.menu-item-has-children)
		> a
			span
				display: inline-block
				position: relative
				&:before
					content: ''
					position: absolute
					width: 0
					height: 1px
					background-color: $white
					bottom: 0
					right: 5px
					+trans1
			&:hover
				color: $white
				span
					&:before
						left: 5px
						width: calc(100% - 5px)
.menu-overlay__item-wrapper
.menu-overlay
	.sub-menu
		display: flex
		align-items: center
		justify-content: center
		flex-direction: column
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		+reset-ul
		z-index: 100
		> li
			display: block
			> a
				display: block
				position: relative
				font-size: 24px
				font-weight: bold
				overflow: hidden
				color: $white
				span
					display: inline-block
				.menu-overlay__item-wrapper
					padding: 10px 15px
				&:hover
					color: $gray
		> li:not(.menu-item-has-children)
			> a
				&:hover
					color: $white
				.menu-overlay__item-wrapper
					&:hover
						&:before
							left: 15px
							width: calc(100% - 30px)
					&:before
						content: ''
						position: absolute
						width: 0
						height: 1px
						background-color: $white
						bottom: 10px
						right: 15px
						+trans1

@media only screen and (max-width: $sm)
	.menu-overlay
		> li
			> a
				font-size: 30px
		.sub-menu
			> li
				> a
					font-size: 18px
