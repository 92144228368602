/* ======================================================================== */
/* 1. articlePost */
/* ======================================================================== */
.post
.post__content, .post__comments
  > *:not( .alignwide ):not( .alignfull )
    margin-left: auto
    margin-right: auto
    max-width: 800px
    padding-left: 15px
    padding-right: 15px
  > *.alignwide
    max-width: 1170px
    margin-left: auto
    margin-right: auto
    display: block
.post__content, .post__comments
  img:not(.avatar)
    margin-top: 3em
    margin-bottom: 3em
.post__header
.post__header
  max-width: 800px
  margin-bottom: 1.5em
  padding-left: 15px
  padding-right: 15px
  margin-left: auto
  margin-right: auto
  h2
    font-family: $font-secondary
    font-size: 46px
    font-weight: bold
.post__footer
  max-width: 1170px
  margin-left: auto
  margin-right: auto
  padding-left: 15px
  padding-right: 15px
  border-top: 1px solid #e5e5e5
  margin-top: 5em
.post__properties
  max-width: 770px
  margin-left: auto
  margin-right: auto
  padding-top: 10px
  .properties
    display: flex
    align-items: center
    justify-content: space-between
.post__comments
  margin-top: 120px

@media only screen and (max-width: $xxl)
  .post__comments
    margin-top: 80px

@media only screen and (max-width: $xl)
  .post__content > *.alignwide, .post__comments > *.alignwide
    max-width: 100%
  .post__content > *:not(.alignwide):not(.alignfull), .post__comments > *:not(.alignwide):not(.alignfull)
    max-width: 800px

@media only screen and (max-width: $sm)
  .post__header h2
    font-size: 33px
  .post__comments
    margin-top: 60px
