/* ======================================================================== */
/* 30. header */
/* ======================================================================== */
.header
	.social__icon
		padding: 5px
		background-color: rgba(24, 24, 24, 0.2)
		width: 40px !important
		height: 40px !important
		font-size: 14px
.header__container
	padding-left: 50px
	padding-right: 50px
	padding-top: 25px
	padding-bottom: 25px
.header__row
	margin-left: -25px
	margin-right: -25px
.header__col
	padding-left: 25px
	padding-right: 25px
.header__wrapper-left
.header__wrapper-center
.header__wrapper-right
	margin-left: auto
.header_absolute
	position: absolute
	left: 0
	top: 0
	right: 0
	z-index: 500
	&.header_white
		.menu
			> li
				> a
					color: $white
			> li:not(.menu-item-has-children)
				> a
					&:before
						background-color: $white
					&:hover
						color: $white
		.burger__line
			background-color: $white
		.burger.burger_opened
			.burger__line
				background-color: $white
.header__wrapper-overlay-menu
	display: flex
	flex-wrap: wrap
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	z-index: 500
	overflow-x: hidden
	overflow-y: auto
	-webkit-overflow-scrolling: touch
	padding-top: 120px
	padding-bottom: 120px
	opacity: 0
	visibility: hidden
	will-change: opacity, visibility
.header__overlay
	z-index: -1
	opacity: .6 !important
.header__diagonal-curtain-1, .header__diagonal-curtain-2
	position: absolute
	width: 3000px
	height: 3000px
	z-index: 0
.header__diagonal-curtain-1
	bottom: 100%
	// right: 200px
	right: 20%
	transform: rotate(-49deg)
	transform-origin: bottom right
	background-color: $black
.header__diagonal-curtain-2
	bottom: 0%
	// right: 200px
	left: 80%
	// right: 20%
	transform: rotate(-49deg)
	transform-origin: bottom right
	background-color: $black
.header__wrapper-background
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: -1
	overflow: hidden
.header__overlay-menu-back
	position: fixed
	top: 65px
	left: 50px
	z-index: 100
	color: $white
	font-size: 30px
	cursor: pointer
.header__background
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: -2
	// +trans2
	// opacity: 0
	// visibility: hidden
	// transform: scale(1.1)
	will-change: opacity, visibility, transform
// .header__background_active
// 	transform: scale(1)
// 	opacity: 1
// 	visibility: visible
.header__wrapper-overlay-widgets
	margin: 15px 0
	text-align: center
	width: 100%

@media only screen and (max-width: $xl)
	//
	.header__container
		padding-left: 30px
		padding-right: 30px
	.header__overlay-menu-back
		top: 45px
		left: 30px
	.header__wrapper-overlay-menu
		padding-top: 100px
		padding-bottom: 100px

@media only screen and (max-width: $md)
	.header
		.logo
			height: 60px

@media only screen and (max-width: $sm)
	//
	.header__container
		padding-left: 15px
		padding-right: 15px
		padding-top: 15px
		padding-bottom: 15px
	.header__row
		margin-left: -15px
		margin-right: -15px
	.header__col
		padding-left: 15px
		padding-right: 15px
	.header__overlay-menu-back
		top: 32px
		left: 15px
