/* ======================================================================== */
/* 25. footer */
/* ======================================================================== */
.footer
  padding-top: 135px
  padding-bottom: 135px
.footer__wrapper-widget
  padding-top: 25px
  padding-bottom: 25px

@media only screen and (max-width: $xxl)
  .footer
    padding-top: 95px
    padding-bottom: 95px

@media only screen and (max-width: $xl)
  .footer
    padding-top: 55px
    padding-bottom: 55px
  .footer__wrapper-widget
    padding-top: 15px
    padding-bottom: 15px

@media only screen and (max-width: $sm)
  .footer
    padding-top: 45px
    padding-bottom: 45px
