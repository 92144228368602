/* ======================================================================== */
/* 26. form */
/* ======================================================================== */
.form
  .form__row:last-child
    .form__col
      margin: 12px 0 0
.form__col
  margin: 12px 0
.form__header
  h3
    margin-top: 0
    margin-bottom: 0
    font-size: 26px
  margin-bottom: 15px
