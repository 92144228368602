/* ======================================================================== */
/* 4. asideCounters */
/* ======================================================================== */
.aside-counters
	&.section_pb
		padding-bottom: 80px
	&.section_pt
		padding-top: 80px
.aside-counters__wrapper-item
	position: relative
	margin-bottom: 60px
	margin-top: 60px
	&:after
		content: ''
		display: block
		position: absolute
		top: 10px
		bottom: 10px
		right: 0
		width: 1px
		background-color: $dark
		transform: rotate(30deg)
	&:nth-of-type(4n)
		&:after
			display: none

@media only screen and (max-width: $xl)
	.aside-counters
		&.section_pb
			padding-bottom: 60px
		&.section_pt
			padding-top: 60px
		.aside-counters__wrapper-item
			margin-bottom: 40px
			margin-top: 40px
			&:nth-child(2n)
				&:after
					display: none

@media only screen and (max-width: $sm)
	.aside-counters
		&.section_pb
			padding-bottom: 40px
		&.section_pt
			padding-top: 40px
		.aside-counters__wrapper-item
			margin-bottom: 20px
			margin-top: 20px

@media only screen and (max-width: $xs)
	.aside-counters__wrapper-item
		&:after
			display: none
