/* ======================================================================== */
/* 19. figureMember */
/* ======================================================================== */
.figure-member
	text-align: center
	&:hover
		.figure-member__overlay
			transform: scaleY(1)
		.figure-member__social
			.social__item
				transform: translateY(0px)
				opacity: 1
				visibility: visible
				@for $i from 1 through 10
					&:nth-child(#{$i})
						transition-delay: 300ms + $i * 30ms
		.figure-member__line
			transform: scaleX(1)
      transition-delay: 300ms
		.figure-member__content p
			opacity: 1
			visibility: visible
			transform: translateY(0px)
			transition-delay: 200ms
.figure-member__name
	font-family: $font-secondary
	font-weight: bold
	font-size: 18px
.figure-member__position
.figure-member__wrapper-img
	position: relative
	img
		position: relative
		z-index: -2
.figure-member__wrapper-info
	margin-top: 30px
.figure-member__hover-content
	display: flex
	align-items: center
	justify-content: center
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	// z-index: 50
.figure-member__content
	padding: 30px
	position: relative
	z-index: 50
	color: $white
	p
		+trans1
		opacity: 0
		visibility: hidden
		transform: translateY(20px)
.figure-member__line
	width: 100%
	height: 1px
	background-color: $white
	margin-top: 1em
	margin-bottom: 1em
	transform: scaleX(0)
	+trans2
.figure-member__social
	.social__icon
		background-color: rgba(24, 24, 24, 0.4)
	.social__item
		margin: 6px
		+trans1
		opacity: 0
		visibility: hidden
		transform: translateY(20px)
.figure-member__overlay
	+trans1
	transform: scaleY(0)
	transform-origin: center bottom

@media only screen and (max-width: $md)
	.figure-member__wrapper-info
		margin-top: 20px
