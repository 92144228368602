/* ======================================================================== */
/* 21. figurePricing */
/* ======================================================================== */
.figure-pricing
  position: relative
  display: flex
  flex-direction: column
  width: 100%
  height: 100%
  background-color: $white
  padding: 50px 35px
  z-index: 50
  h5
    font-size: 20px
    font-weight: normal
    margin-top: 0
    margin-bottom: 0
  &.text-center
    .figure-pricing__cost
      justify-content: center
.figure-pricing_bordered:not(.figure-pricing_featured)
  border: 1px solid $light2
.figure-pricing_featured
  &:before
    content: ''
    display: block
    position: absolute
    top: -50px
    bottom: -50px
    left: 0
    right: 0
    background-color: $white
    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.1)
    z-index: -1
.figure-pricing_transparent
  background-color: rgba(0, 0, 0, 0.5)
  color: $white
  .figure-pricing__label, .figure-pricing__feature
    color: $white
  .figure-pricing__divider
    background-color: rgba(255, 255, 255, .2)
.figure-pricing_transparent.figure-pricing_featured
  background-color: transparent
  &:before
    background-color: rgba(0, 0, 0, 0.5)
.figure-pricing__cost
  display: flex
.figure-pricing__cost-sign
  font-size: 23px
  margin-right: 7px
  margin-top: 3px
.figure-pricing__amount
  font-size: 64px
  line-height: 1.1
  font-weight: bold
  font-family: $font-secondary
  text-align: center
.figure-pricing__label
  font-family: $font-primary
  font-size: 13px
  color: $gray2
  font-weight: normal
.figure-pricing__header
  margin-bottom: 30px
.figure-pricing__features
  +reset-ul
.figure-pricing__feature
  display: block
  color: $gray2
  margin-top: 16px
  margin-bottom: 16px
.figure-pricing__divider
  width: 100%
  height: 1px
  background-color: $light2
  margin-top: 35px
  margin-bottom: 19px
.figure-pricing__feature_yes
  &:before
    content: '\4e'
    @extend .elegant-icons
    color: #9bca7d
    display: inline-block
    vertical-align: middle
    margin-right: 8px
.figure-pricing__feature_no
  &:before
    content: '\4d'
    @extend .elegant-icons
    color: $gray2
    font-size: 17px
    vertical-align: middle
    margin-right: 6px
.figure-pricing__wrapper-button
  margin-top: auto
  padding-top: 35px
.figure-pricing__feature-label
  font-size: 11px
  text-transform: uppercase
  font-weight: bold
  background-color: $accent2
  color: $white
  display: inline-block
  position: absolute
  top: -25px
  left: 0
  padding: 5px 15px 5px 15px
  z-index: 50
  &:before, &:after
    content: ''
    display: block
    position: absolute
    left: 100%
    width: 0
    height: 0
    border-style: solid
  &:before
    top: 0
    border-width: 13px 13px 0 0
    border-color: $accent2 transparent transparent transparent
  &:after
    bottom: 0
    border-width: 13px 0 0 13px
    border-color: transparent transparent transparent $accent2

@media only screen and (max-width: $xl)
  .figure-pricing
    padding: 50px 30px
  .figure-pricing_featured
    &:before
      top: -25px
      bottom: -25px
  .figure-pricing__feature-label
    top: 0
  .figure-pricing__header
    margin-bottom: 20px

@media only screen and (max-width: $sm)
  .figure-pricing_featured
    margin-top: 25px
    margin-bottom: 25px
    height: auto
