/* ======================================================================== */
/* 72. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials
	padding-top: 190px
	padding-bottom: 190px
.slider-testimonials__slide
	p
		margin-top: 0
		font-weight: 300
		font-size: 28px
		line-height: 1.5357
.slider-testimonials__name
	font-weight: bold
.slider-testimonials__wrapper-slider-nav
	position: absolute
	left: 0
	right: 0
	bottom: 30px
	z-index: 100
	text-align: center

@media only screen and (max-width: $xl)
	.slider-testimonials
		padding-top: 100px
		padding-bottom: 100px
	.slider-testimonials__slide p
		font-size: 24px

@media only screen and (max-width: $sm)
	.slider-testimonials
		padding-top: 60px
		padding-bottom: 80px
	.slider-testimonials__wrapper-slider-nav
		bottom: 15px
