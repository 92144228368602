/* ======================================================================== */
/* 70. sliderProjectContent */
/* ======================================================================== */
.slider-project-content__slide
  position: relative
  // overflow: hidden
  display: flex
  // @for $i from 1 through 10
  //   &:nth-child(#{$i})
  //     .slider-project-content__wrapper-image svg
  //       z-index: -#{$i}
  &.swiper-slide-prev
.slider-project-content__slide-inner
.slider-project-content__wrapper-overlay
  position: absolute
  top: 0
  right: 34.5%
  bottom: 0
  width: 100%
  max-width: 820px
  mix-blend-mode: multiply
  opacity: 0.95
.slider-project-content__diagonal-overlay
  // &:after
  //   content: ''
  position: relative
  display: block
  width: 100%
  height: 100%
  background-color: $accent
  transform: skewX(-44.25deg)
.slider-project-content__wrapper-image svg
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
.slider-project-content__wrapper-content
  display: flex
  flex-direction: column
  justify-content: center
  // align-items: center
  padding-top: 140px
  padding-bottom: 140px
  min-height: 820px
  position: relative
  h3
    font-family: $font-primary
    font-size: 128px
    line-height: 1.2
    letter-spacing: 3.7px
    font-weight: 300
    margin-top: 0
    margin-bottom: 0
  p
    font-size: 20px
    font-weight: 300
.slider-project-content__wrapper-button
  position: absolute
  bottom: 46px
  left: 15px
.slider-project-content__wrapper-slider-nav
  position: absolute
  display: inline-block
  left: 50%
  bottom: 14px
  transform: translateX(-50%)
  z-index: 60
.slider-project-content__prev, .slider-project-content__next
  position: absolute
  top: 50%
  transform: translateY(-50%)
  z-index: 60
.slider-project-content__prev
  left: 50px
.slider-project-content__next
  right: 50px

@media only screen and (max-width: $xxl)
  .slider-project-content__prev
    left: 30px
  .slider-project-content__next
    right: 30px

@media only screen and (max-width: $xl)
  .slider-project-content__wrapper-content
    h3
      font-size: 96px
    p
      font-size: 18px
  .slider-project-content__wrapper-button
    left: calc(8.33333% + 15px)
  .slider-project-content__wrapper-content
    padding-top: 100px
    padding-bottom: 100px
    min-height: 700px

@media only screen and (max-width: $md)
  //
  .slider-project-content__wrapper-overlay
    right: 25%
  .slider-project-content__wrapper-content
    text-align: center
    h3
      font-size: 72px
      letter-spacing: 2px
    p
      font-size: 16px
  .slider-project-content__wrapper-content
    min-height: 600px
  .slider-project-content__wrapper-button
    margin-top: 30px
    position: relative
    bottom: auto
    left: auto

@media only screen and (max-width: $sm)
  .slider-project-content__wrapper-content
    h3
      font-size: 38px
  .slider-project-content__wrapper-overlay
    right: 0%
  .slider-project-content__slide
    overflow: hidden
  .slider-project-content__wrapper-button
    margin-top: 20px
  .slider-project-content__wrapper-content
    min-height: 500px
