/* ======================================================================== */
/* 54. sectionMasthead */
/* ======================================================================== */
.section-masthead
  display: flex
  h1:not(.section-masthead__subheading)
    font-family: $font-primary
    font-size: 15px
    text-transform: uppercase
    font-weight: bold
    margin-top: 0
    margin-bottom: 1.5em
.section-masthead__header
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  position: relative
  z-index: 50
  min-height: 500px
.section-masthead__header_background
  padding-top: 250px
  padding-bottom: 250px
  min-height: 800px
.section-masthead__subheading
  font-size: 60px
  line-height: 1.3
  font-family: $font-secondary


@media only screen and (max-width: $xxl)
  .section-masthead__header_background
    padding-top: 160px
    padding-bottom: 160px
    min-height: 700px

@media only screen and (max-width: $xl)
  .section-masthead__header_background
    padding-top: 100px
    padding-bottom: 100px
    min-height: 500px
  .section-masthead__subheading
    font-size: 45px

@media only screen and (max-width: $sm)
  .section-masthead__header_background
    padding-top: 120px
    padding-bottom: 60px
    min-height: 360px
  .section-masthead
    h1
      font-size: 12px
  .section-masthead__subheading
    font-size: 33px
