/* ======================================================================== */
/* 27. formControl */
/* ======================================================================== */
.form-control
  display: block
.form-control_inline
  display: flex
  align-items: center
  justify-content: space-between
  width: 100%
.form-control_inline-textarea
  align-items: flex-start
.form-control__label
  display: block
  font-family: $font-secondary
  font-size: 15px
  margin-bottom: 10px
.form-control__label_inline
  flex: 1 1 auto
  margin-bottom: 0
.form-control__input
  display: block
  width: 100%
  outline: none
  background-color: transparent
  color: inherit
  border-radius: 25px
  background: $white
  border: none
  padding: 12px 24px
  border: 1px solid transparent
  +trans1
  &:focus
    border-color: $accent
.form-control__input_semi-transparent
  background: rgba(0, 0, 0, .5)
.form-control__input_light
  background-color: $light
.form-control__input_textarea
  resize: none
  height: 250px
.form-control__wrapper-input
  position: relative
  max-width: 430px
  width: 100%
.form-control__input_inline
.form-control__label_inline-textarea
  margin-top: 12px
.form-control__error
  color: inherit
  font-size: 10px
  font-weight: bold
  color: red

\::-webkit-input-placeholder
  color: $gray
\::-moz-placeholder
  color: $gray
\:-moz-placeholder
  color: $gray
\:-ms-input-placeholder
  color: $gray

@media only screen and (max-width: $md)
  .form-control_inline
    display: block
  .form-control__label_inline
    margin-bottom: 15px
  .form-control__label_inline-textarea
    margin-top: 0
