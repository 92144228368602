/* ======================================================================== */
/* 56. sectionPricing */
/* ======================================================================== */
.section-pricing__wrapper-item
	margin-top: 50px
	margin-bottom: 50px

@media only screen and (max-width: $xl)
	.section-pricing__wrapper-item
		margin-top: 40px
		margin-bottom: 40px

@media only screen and (max-width: $sm)
	.section-pricing__wrapper-item
		margin-top: 15px
		margin-bottom: 15px
