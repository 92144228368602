/* ======================================================================== */
/* 8. asideProjectsNav */
/* ======================================================================== */
.aside-projects-nav
  padding: 70px 0
.aside-projects-nav__wrapper-left
  text-align: left
.aside-projects-nav__wrapper-center
  text-align: center
.aside-projects-nav__wrapper-right
  text-align: right
.aside-projects-nav__prev, .aside-projects-nav__next, .aside-projects-nav__all
  display: inline-block
  color: $gray2
.aside-projects-nav__prev
  &:before
    content: '\23'
    display: inline-block
    font-family: 'ElegantIcons'
    speak: none
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 26px
    vertical-align: middle
    margin-right: 20px
    +trans1
  &:hover
    &:before
      transform: translateX(-5px)
.aside-projects-nav__next
  &:after
    content: '\24'
    display: inline-block
    font-family: 'ElegantIcons'
    speak: none
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 26px
    vertical-align: middle
    margin-left: 20px
    +trans1
  &:hover
    &:after
      transform: translateX(5px)
.aside-projects-nav__all
  &:after
    content: '\e08d'
    font-family: 'ElegantIcons'
    speak: none
    font-weight: normal
    font-variant: normal
    text-transform: none
    line-height: 1
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    font-size: 32px
    vertical-align: middle
.aside-projects-nav__col
  margin-top: 15px
  margin-bottom: 15px

@media only screen and (max-width: $xxl)
  .aside-projects-nav
    padding: 50px 0

@media only screen and (max-width: $xl)
  .aside-projects-nav
    padding: 30px 0

@media only screen and (max-width: $sm)
  .aside-projects-nav__wrapper-left, .aside-projects-nav__wrapper-center, .aside-projects-nav__wrapper-right
    text-align: center
