/* ======================================================================== */
/* 45. sectionBlog */
/* ======================================================================== */
.section-blog__wrapper-button
  margin-top: 40px
.section-blog__wrapper-post
  margin-bottom: 30px

@media only screen and (max-width: $xxl)
  .section-blog__wrapper-button
    margin-top: 30px
