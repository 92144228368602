/* ======================================================================== */
/* 49. sectionFeatures */
/* ======================================================================== */
.section-features
	&.section_mb
		margin-bottom: 100px
.section-features__wrapper-item
	margin-bottom: 60px

@media only screen and (max-width: $xl)
	.section-features
		&.section_mb
			margin-bottom: 60px
	.section-features__wrapper-item
		margin-bottom: 40px
