/* ======================================================================== */
/* 62. share */
/* ======================================================================== */
.share
  display: inline-block
  +reset-ul
.share__item
  display: inline-block
.share__icon
  display: block
  color: $gray2
  padding: 6px
