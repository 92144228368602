/* ======================================================================== */
/* 55. sectionPortfolio */
/* ======================================================================== */
.section-portfolio__wrapper-tabs
  margin-bottom: 40px
.section-portfolio__wrapper-button
  margin-top: 70px

@media only screen and (max-width: $md)
  .section-portfolio__wrapper-tabs
    margin-bottom: 30px
