/* ======================================================================== */
/* 24. filter */
/* ======================================================================== */
.filter
  padding-bottom: 0 !important
.filter__item
  padding: 10px 30px
  h4
    font-family: $font-primary
    text-transform: uppercase
    font-weight: bold
    cursor: pointer
    display: inline-block
    font-size: 15px !important
    letter-spacing: 0.5px
  &.tabs__item_active
    color: $accent
    &:hover
      color: $accent
.filter__underline
  background-color: $accent !important

@media only screen and (max-width: $sm)
  .filter__item
    margin-top: 0 !important
    margin-bottom: 0 !important
