/* ======================================================================== */
/* 71. sliderServices */
/* ======================================================================== */
.slider-services
	h4
		font-size: 23px
		line-height: 36px
		font-weight: 300
		font-family: $font-primary
		margin-top: 1.5em
		margin-bottom: 1.5em
.slider-services__slide
.slider-services__headline
	width: 100%
	height: 1px
	background-color: $light2
.slider-services__header
	padding-top: 20px
.slider-services__wrapper-text
	padding-top: 0px
	

@media only screen and (max-width: $md)
	.slider-services h4
		margin-top: 1em
		margin-bottom: 1em
