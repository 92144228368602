/* ======================================================================== */
/* 31. link */
/* ======================================================================== */
.link
  display: inline-block
  font-size: 13px
  font-weight: bold
  text-transform: uppercase
  color: inherit
  &:hover
    color: $accent
.link__text
  display: inline-block
  vertical-align: middle
.link__icon
  display: inline-block
  vertical-align: middle
