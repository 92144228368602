/* ======================================================================== */
/* 41. scrollDown */
/* ======================================================================== */
.scroll-down
  display: inline-block
  text-align: center
  cursor: pointer
  +trans1
  &:hover
    transform: translateY(5px)
.scroll-down__label
  display: block
  font-size: 12px
  font-weight: bold
  text-transform: uppercase
  letter-spacing: 0.24px
  margin-bottom: 14px
.scroll-down__icon
  display: inline-block
  width: 40px
  img
    width: 100%

@media only screen and (max-width: $md)
  .scroll-down__icon
    width: 30px
