/* ======================================================================== */
/* 76. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5, h6
  font-family: $font-secondary
h1
  font-size: 60px
  margin: 0.25em 0
  line-height: 1.2
h2
  font-size: 50px
  margin: 0.5em 0
  line-height: 1.25
h3
  font-size: 46px
  margin: 0.75em 0
  line-height: 1.3
h4
  font-size: 30px
  margin: 1em 0
  line-height: 1.4
h5
  font-size: 21px
  margin: 1em 0
h6
  font-size: 18px
  margin: 1em 0
p
  margin-top: 1em
  margin-bottom: 1em
blockquote
  display: block
  font-size: 20px
  line-height: 1.5
  margin-top: 2em
  margin-bottom: 2em
  p
    margin-top: 0.5em
  cite
    display: block
    font-family: $font-secondary
    font-size: 15px
    font-weight: bold
    font-style: normal
    margin-top: 20px
  &:before
    content: '{'
    @extend .elegant-icons
    color: $accent
    font-size: 40px
    margin-left: -10px


@media only screen and (max-width: $xl)
  h1
    font-size: 46px
  h2
    font-size: 37px
  h3
    font-size: 34px
  h4
    font-size: 25px
  h5
    font-size: 18px
  h6
    font-size: 16px

@media only screen and (max-width: $sm)
  h1
    font-size: 42px
  h2
    font-size: 33px
  h3
    font-size: 30px
  h4
    font-size: 22px
  h5
    font-size: 16px
  h6
    font-size: 14px
