/* ======================================================================== */
/* 23. figureService */
/* ======================================================================== */
.figure-service
  position: relative
  padding: 125px 15px 175px 15px
  height: 100%
  z-index: 50
  +trans1
  h3
    font-size: 21px
    line-height: 1.42857
    margin-top: 0
    margin-bottom: 1.25em
    font-family: $font-secondary
.figure-service__counter
  font-size: 16px
  display: inline-block
  position: absolute
  top: 22px
  right: 12px
.figure-service__headline
  width: 45px
  height: 1px
  background-color: $white
  margin-top: 1.25em
  margin-bottom: 1.7em
.figure-service__wrapper-button
  display: inline-block
  position: absolute
  right: 12px
  bottom: 28px
.figure-service__icon
  font-size: 64px
  margin-bottom: 15px
  &:before
    margin-left: 0 !important
    margin-right: 0 !important

// @media only screen and (max-width: $xxl)
//   .figure-service
//     padding: 105px 15px 105px 15px

@media only screen and (max-width: $xl)
  .figure-service
    padding: 80px 15px 130px 15px
