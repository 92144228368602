\:root
	--color-accent-primary: #1869ff
	--color-accent-primary-rgb: 24, 105, 255
	--color-accent-secondary: #b388ff
	--color-accent-secondary-rgb: 179, 136, 255
	--font-primary: 'Open Sans'
	--font-secondary: 'Montserrat'
html
	font-size: $em-base
	-webkit-font-smoothing: antialiased
	text-rendering: optimizeLegibility
body
	position: relative
	width: 100%
	min-width: 320px
	// color: $dark
	font-family: $font-primary
	font-size: 100%
	line-height: 1.53
.body_lock-scroll
	overflow: hidden
	position: fixed
	top: 0
	right: 0
	bottom: 0
	left: 0
	height: 100%
img
	max-width: 100%
	height: auto
a
	+trans1
	&:hover, &:focus
		text-decoration: none
	&:focus
		outline: none
.page-wrapper
	+trans1
.page-wrapper_hidden
	opacity: 0
	visibility: hidden
.jarallax, .jarallax-video
	overflow: hidden
