/* ======================================================================== */
/* 5. asideLinks */
/* ======================================================================== */
.aside-links
.aside-links__item
	&:nth-child(odd)
		background-color: $black
	&:nth-child(even)
		background-color: #222
.aside-links__item
	display: block
	color: $white
	text-align: center
	padding: 140px 30px
	h2
		margin-top: 0.25em
		margin-bottom: 0
		font-size: 34px
	&:hover
		background-color: $accent
		color: $white
.aside-links__subheading
	font-size: 14px
	text-transform: uppercase
	letter-spacing: 1px
.aside-links__wrapper-button
	position: absolute
	right: 30px
	bottom: 30px
	.button
		height: 30px
	.button__icon
		height: 100%
	.button__text_line-right:after
		margin-right: -8px

@media only screen and (max-width: $xxl)
	.aside-links__item
		padding: 120px 30px

@media only screen and (max-width: $xl)
	.aside-links__item
		padding: 100px 30px

@media only screen and (max-width: $md)
	.aside-links__item
		padding: 80px 30px 60px
	.aside-links__wrapper-button
		margin-top: 40px
		position: relative
		right: auto
		bottom: auto

@media only screen and (max-width: $sm)
  .aside-links__subheading
    font-size: 12px
		margin-bottom: 5px
