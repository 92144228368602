/* ======================================================================== */
/* 14. container */
/* ======================================================================== */
.container-fluid_50
  padding-left: 50px
  padding-right: 50px

@media only screen and (max-width: $xl)
  .container-fluid_50
    padding-left: 30px
    padding-right: 30px

@media only screen and (max-width: $sm)
  .container-fluid_50
    padding-left: 15px
    padding-right: 15px
