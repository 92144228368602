/* ======================================================================== */
/* 11. burger */
/* ======================================================================== */
.burger
  position: relative
  display: inline-flex
  flex-direction: column
  align-items: center
  justify-content: center
  width: 38px
  height: 38px
  cursor: pointer
  padding: 0 6px
  z-index: 600
.burger__line
  width: 100%
  height: 2px
  margin: 2px 0
  background-color: $accent
  +trans1
.burger_opened, .burger_opened:hover
  .burger__line
    &:nth-of-type(1)
      transform: scaleX(1) rotate(45deg) translate(7px, 0px)
    &:nth-of-type(2)
      transform: scaleX(0)
    &:nth-of-type(3)
      transform: scaleX(1) rotate(-45deg) translate(8px, -1px)
