/* ======================================================================== */
/* 38. postMeta */
/* ======================================================================== */
.post-meta
  +reset-ul
  margin: 0 -10px
.post-meta__item
  display: inline-block
  margin: 0 10px
  font-size: 13px
.post-meta__item-text
  color: $gray2
.post-meta__link
  display: inline-block
  vertical-align: middle
  color: $black
  &:hover
    color: $accent
.post-meta__item-icon
  display: inline-block
  vertical-align: middle
.post-meta__item-text
  display: inline-block
  vertical-align: middle
  margin-left: 5px
