/* ======================================================================== */
/* 40. properties */
/* ======================================================================== */
.properties
  +reset-ul
  margin: 0 -25px
.properties__item
  display: inline-block
  vertical-align: middle
  margin: 10px 25px
.properties__name
  display: inline-block
  font-size: 13px
  line-height: 1.538
  font-weight: bold
  text-transform: uppercase
.properties__value
  display: inline-block
  font-size: 14px
  color: $gray2
  margin-left: 10px

@media only screen and (max-width: $sm)
  .properties__item
    display: block
    width: 100%
@media only screen and (max-width: $md)
  .properties
    margin: 0 -15px
  .properties__item
    margin: 10px 15px
  .post__properties .properties
    flex-wrap: wrap
