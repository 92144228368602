/* * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 *
 * Harizma – Agency HTML5 Template
 *
 * [Table of Contents]
 *
 * 1. articlePost
 * 2. articleProject
 * 3. aside
 * 4. asideCounters
 * 5. asideLinks
 * 6. asideLogos
 * 7. asideNextPost
 * 8. asideProjectsNav
 * 9. avatar
 * 10. backgrounds
 * 11. burger
 * 12. button
 * 13. comments
 * 14. container
 * 15. copyright
 * 16. counter
 * 17. figureContact
 * 18. figureFeature
 * 19. figureMember
 * 20. figurePost
 * 21. figurePricing
 * 22. figureProcess
 * 23. figureService
 * 24. filter
 * 25. footer
 * 26. form
 * 27. formControl
 * 28. gmap
 * 29. grid
 * 30. header
 * 31. link
 * 32. logo
 * 33. menu
 * 34. menuOverlay
 * 35. ofi
 * 36. overlay
 * 37. pagination
 * 38. postMeta
 * 39. preloader
 * 40. properties
 * 41. scrollDown
 * 42. scrollUp
 * 43. section
 * 44. section404
 * 45. sectionAbout
 * 46. sectionBlog
 * 47. sectionContacts
 * 48. sectionCTA
 * 49. sectionFeatures
 * 50. sectionIntro
 * 51. sectionLatestNews
 * 52. sectionLatestProjects
 * 53. sectionMap
 * 54. sectionMasthead
 * 55. sectionPortfolio
 * 56. sectionPricing
 * 57. sectionProcess
 * 58. sectionServices
 * 59. sectionServicesTabs
 * 60. sectionTeam
 * 61. sectionTestimonials
 * 62. share
 * 63. sidebar
 * 64. slider
 * 65. sliderGallery
 * 66. sliderImages
 * 67. sliderIntroBackgrounds
 * 68. sliderIntroContent
 * 69. sliderProjectBackgrounds
 * 70. sliderProjectContent
 * 71. sliderServices
 * 72. sliderTestimonials
 * 73. social
 * 74. tabs
 * 75. tags
 * 76. typography
 * 77. utilities
 * 78. widget

 * ==========================================================================
 * ==========================================================================
 * ==========================================================================
 */

@function rem($size) {
  $remSize: $size / $em-base;
  @return #{$remSize}rem; }
@mixin trans1 {
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin trans2 {
  transition: all 0.6s cubic-bezier(0.785, 0.135, 0.150, 0.860); }
@mixin reset-ul {
  list-style-type: none;
  padding: 0;
  margin: 0; }

@mixin link-underline {
  &:after, &:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0;
    right: 0;
    height: 1px;
    background-color: $white; }
  &:before {
    transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s; }
  &:after {
    transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83); }
  &:after, &:before {
    width: calc(100% - 10px);
    left: 0; } }

@mixin material-icons {
  font-family: 'Material Icons';
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  ms-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

@mixin shadow {
  box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1); }

$em-base: 15px;
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Montserrat', sans-serif;

// Color palette

$black: #1a1a1a;
$black2: #0f0f0f;
$dark: #333333;
$accent: var(--color-accent-primary);
$accent2: var(--color-accent-secondary);
$light: #f5f5f5;
$light2: #ebebeb;
$white: #ffffff;
$gray: #adadad;
$gray2: #8f8f8f;

// Responsive breakpoints
$xxl: 1400px;
$xl: 1200px;
$lg: 1024px;
$md: 991px;
$sm: 767px;
$xs: 576px;

 :root {
	--color-accent-primary: #1869ff;
	--color-accent-primary-rgb: 24, 105, 255;
	--color-accent-secondary: #b388ff;
	--color-accent-secondary-rgb: 179, 136, 255;
	--font-primary: 'Open Sans';
	--font-secondary: 'Montserrat'; }
html {
	font-size: $em-base;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility; }
body {
	position: relative;
	width: 100%;
	min-width: 320px;
	// color: $dark
	font-family: $font-primary;
	font-size: 100%;
	line-height: 1.53; }
.body_lock-scroll {
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%; }
img {
	max-width: 100%;
	height: auto; }
a {
	@include trans1;
	&:hover, &:focus {
		text-decoration: none; }
	&:focus {
		outline: none; } }
.page-wrapper {
	@include trans1; }
.page-wrapper_hidden {
	opacity: 0;
	visibility: hidden; }
.jarallax, .jarallax-video {
	overflow: hidden; }

/* ======================================================================== */
/* 1. articlePost */
/* ======================================================================== */
.post {}
.post__content, .post__comments {
  > *:not( .alignwide ):not( .alignfull ) {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    padding-left: 15px;
    padding-right: 15px; }
  > *.alignwide {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    display: block; } }
.post__content, .post__comments {
  img:not(.avatar) {
    margin-top: 3em;
    margin-bottom: 3em; } }
.post__header {}
.post__header {
  max-width: 800px;
  margin-bottom: 1.5em;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  h2 {
    font-family: $font-secondary;
    font-size: 46px;
    font-weight: bold; } }
.post__footer {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #e5e5e5;
  margin-top: 5em; }
.post__properties {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  .properties {
    display: flex;
    align-items: center;
    justify-content: space-between; } }
.post__comments {
  margin-top: 120px; }

@media only screen and (max-width: $xxl) {
  .post__comments {
    margin-top: 80px; } }

@media only screen and (max-width: $xl) {
  .post__content > *.alignwide, .post__comments > *.alignwide {
    max-width: 100%; }
  .post__content > *:not(.alignwide):not(.alignfull), .post__comments > *:not(.alignwide):not(.alignfull) {
    max-width: 800px; } }

@media only screen and (max-width: $sm) {
  .post__header h2 {
    font-size: 33px; }
  .post__comments {
    margin-top: 60px; } }

/* ======================================================================== */
/* 2. aside */
/* ======================================================================== */
.aside {
  position: relative; }
.aside__content {
  position: relative;
  z-index: 50; }

/* ======================================================================== */
/* 3. articleProject */
/* ======================================================================== */
.project__wrapper-category {
  margin-bottom: 80px;
  span {
    display: block;
    font-size: 13px;
    line-height: 1.538;
    font-weight: bold;
    text-transform: uppercase;
    &:after {
      content: '';
      display: block;
      width: 48px;
      height: 1px;
      background-color: $black;
      margin-top: 14px; } } }
.project__header {
  h2 {
    font-family: $font-secondary;
    font-size: 46px;
    line-height: 1.304;
    margin-top: 0;
    margin-bottom: 40px; } }
.project__wrapper-properties {
  margin-top: 25px; }
.project__wrapper-slider {
  margin-top: 140px; }


@media only screen and (max-width: $xxl) {
  .project__wrapper-category {
    margin-bottom: 40px; }
  .project__header {
    h2 {
      margin-bottom: 30px; } }
  .project__wrapper-slider {
    margin-top: 100px; } }

@media only screen and (max-width: $xl) {
  .project__wrapper-category {
    margin-bottom: 30px; }
  .project__header {
    h2 {
      font-size: 34px;
      margin-bottom: 20px; } }
  .project__wrapper-slider {
    margin-top: 80px; } }

@media only screen and (max-width: $md) {
  .project__wrapper-button {
    margin-top: 15px;
    margin-bottom: 15px; } }

@media only screen and (max-width: $sm) {
  .project__wrapper-slider {
    margin-top: 60px; } }

/* ======================================================================== */
/* 4. asideCounters */
/* ======================================================================== */
.aside-counters {
	&.section_pb {
		padding-bottom: 80px; }
	&.section_pt {
		padding-top: 80px; } }
.aside-counters__wrapper-item {
	position: relative;
	margin-bottom: 60px;
	margin-top: 60px;
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 10px;
		bottom: 10px;
		right: 0;
		width: 1px;
		background-color: $dark;
		transform: rotate(30deg); }
	&:nth-of-type(4n) {
		&:after {
			display: none; } } }

@media only screen and (max-width: $xl) {
	.aside-counters {
		&.section_pb {
			padding-bottom: 60px; }
		&.section_pt {
			padding-top: 60px; }
		.aside-counters__wrapper-item {
			margin-bottom: 40px;
			margin-top: 40px;
			&:nth-child(2n) {
				&:after {
					display: none; } } } } }

@media only screen and (max-width: $sm) {
	.aside-counters {
		&.section_pb {
			padding-bottom: 40px; }
		&.section_pt {
			padding-top: 40px; }
		.aside-counters__wrapper-item {
			margin-bottom: 20px;
			margin-top: 20px; } } }

@media only screen and (max-width: $xs) {
	.aside-counters__wrapper-item {
		&:after {
			display: none; } } }

/* ======================================================================== */
/* 5. asideLinks */
/* ======================================================================== */
.aside-links {}
.aside-links__item {
	&:nth-child(odd) {
		background-color: $black; }
	&:nth-child(even) {
		background-color: #222; } }
.aside-links__item {
	display: block;
	color: $white;
	text-align: center;
	padding: 140px 30px;
	h2 {
		margin-top: 0.25em;
		margin-bottom: 0;
		font-size: 34px; }
	&:hover {
		background-color: $accent;
		color: $white; } }
.aside-links__subheading {
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px; }
.aside-links__wrapper-button {
	position: absolute;
	right: 30px;
	bottom: 30px;
	.button {
		height: 30px; }
	.button__icon {
		height: 100%; }
	.button__text_line-right:after {
		margin-right: -8px; } }

@media only screen and (max-width: $xxl) {
	.aside-links__item {
		padding: 120px 30px; } }

@media only screen and (max-width: $xl) {
	.aside-links__item {
		padding: 100px 30px; } }

@media only screen and (max-width: $md) {
	.aside-links__item {
		padding: 80px 30px 60px; }
	.aside-links__wrapper-button {
		margin-top: 40px;
		position: relative;
		right: auto;
		bottom: auto; } }

@media only screen and (max-width: $sm) {
  .aside-links__subheading {
    font-size: 12px; }
		margin-bottom: 5px; }

/* ======================================================================== */
/* 6. asideLogos */
/* ======================================================================== */
.aside-logos {
  &.section_mt {
    margin-top: 145px; }
  &.section_mb {
    margin-bottom: 145px; } }
.aside-logos__wrapper-logo {
  margin-top: 15px;
  margin-bottom: 15px;
  @include trans1;
  opacity: .6;
  &:hover {
    opacity: 1; } }

@media only screen and (max-width: $xxl) {
  .aside-logos {
    &.section_mt {
      margin-top: 105px; }
    &.section_mb {
      margin-bottom: 105px; } } }

@media only screen and (max-width: $xl) {
  .aside-logos {
    &.section_mt {
      margin-top: 65px; }
    &.section_mb {
      margin-bottom: 65px; } } }

@media only screen and (max-width: $sm) {
  .aside-logos {
    &.section_mt {
      margin-top: 45px; }
    &.section_mb {
      margin-bottom: 45px; } } }

/* ======================================================================== */
/* 7. asideNextPost */
/* ======================================================================== */
.aside-next-post {
  padding: 90px 0;
  h3 {
    font-family: $font-secondary;
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 10px; } }
.aside-next-post__subheading {
  display: block;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 10px; }
.aside-next-post__content {
  color: $white;
  display: block; }

/* ======================================================================== */
/* 8. asideProjectsNav */
/* ======================================================================== */
.aside-projects-nav {
  padding: 70px 0; }
.aside-projects-nav__wrapper-left {
  text-align: left; }
.aside-projects-nav__wrapper-center {
  text-align: center; }
.aside-projects-nav__wrapper-right {
  text-align: right; }
.aside-projects-nav__prev, .aside-projects-nav__next, .aside-projects-nav__all {
  display: inline-block;
  color: $gray2; }
.aside-projects-nav__prev {
  &:before {
    content: '\23';
    display: inline-block;
    font-family: 'ElegantIcons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    vertical-align: middle;
    margin-right: 20px;
    @include trans1; }
  &:hover {
    &:before {
      transform: translateX(-5px); } } }
.aside-projects-nav__next {
  &:after {
    content: '\24';
    display: inline-block;
    font-family: 'ElegantIcons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 26px;
    vertical-align: middle;
    margin-left: 20px;
    @include trans1; }
  &:hover {
    &:after {
      transform: translateX(5px); } } }
.aside-projects-nav__all {
  &:after {
    content: '\e08d';
    font-family: 'ElegantIcons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 32px;
    vertical-align: middle; } }
.aside-projects-nav__col {
  margin-top: 15px;
  margin-bottom: 15px; }

@media only screen and (max-width: $xxl) {
  .aside-projects-nav {
    padding: 50px 0; } }

@media only screen and (max-width: $xl) {
  .aside-projects-nav {
    padding: 30px 0; } }

@media only screen and (max-width: $sm) {
  .aside-projects-nav__wrapper-left, .aside-projects-nav__wrapper-center, .aside-projects-nav__wrapper-right {
    text-align: center; } }

/* ======================================================================== */
/* 9. avatar */
/* ======================================================================== */
.avatar {
  width: 100%;
  max-width: 82px;
  max-height: 82px;
  border-radius: 100%; }

@media only screen and (max-width: $sm) {
  .avatar {
    max-width: 60px;
    max-height: 60px; } }

/* ======================================================================== */
/* 10. backgrounds */
/* ======================================================================== */
.bg-light {
  background-color: $light; }
.bg-dark {
  background-color: $dark; }
.bg-black {
  background-color: $black; }
.bg-black-2 {
  background-color: $black2; }
.bg-white {
  background-color: $white; }
.bg-accent {
  background-color: $accent; }
.bg-fixed {
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }

/* ======================================================================== */
/* 11. burger */
/* ======================================================================== */
.burger {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  cursor: pointer;
  padding: 0 6px;
  z-index: 600; }
.burger__line {
  width: 100%;
  height: 2px;
  margin: 2px 0;
  background-color: $accent;
  @include trans1; }
.burger_opened, .burger_opened:hover {
  .burger__line {
    &:nth-of-type(1) {
      transform: scaleX(1) rotate(45deg) translate(7px, 0px); }
    &:nth-of-type(2) {
      transform: scaleX(0); }
    &:nth-of-type(3) {
      transform: scaleX(1) rotate(-45deg) translate(8px, -1px); } } }

/* ======================================================================== */
/* 12. button */
/* ======================================================================== */
.button {
  position: relative;
  display: inline-block;
  padding: 16px 40px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 25px;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;
  color: $accent;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  @include trans1;
  &:focus {
    outline: none; } }
.button_arrow {
  padding: 0;
  &.button_white {
    color: $white;
    .button__text_line-right {
      &:after {
        background-color: $white;
        @include trans1; } } }
  &:hover {
    .button__text_line-right {
      &:after {
        transform: translateX(5px); } }
    .button__icon {
      transform: translateX(10px); } } }
.button__icon {
  @include trans1;
  display: inline-block; }
.button_bordered {
  &.button_accent {
    border-color: $accent;
    color: $accent;
    &:focus {
      color: $accent; }
    &:hover {
      color: $white;
      background-color: $accent; } }
  &.button_dark {
    border-color: $dark;
    color: $dark;
    &:focus {
      color: $dark; }
    &:hover {
      color: $white; } }
  &.button_light {
    border-color: $light;
    color: $light;
    &:focus {
      color: $light; }
    &:hover {
      color: $dark; } }
  &.button_black {
    border-color: #000;
    color: #000;
    &:focus {
      color: #000; }
    &:hover {
      color: $white; } }
  &.button_white {
    border-color: $white;
    color: $white;
    &:focus {
      color: $white; }
    &:hover {
      color: $dark;
      background-color: $white; } } }
.button_solid {
  &.button_accent {
    background-color: $accent;
    color: $white;
    &:focus {
      color: $white; }
    &:hover {
      border-color: $dark;
      background-color: $dark; }
    &.button_shadow {
      box-shadow: 0px 0px 30px 0px rgba(var(--color-accent-primary-rgb), 0.5);
      &:hover {
        box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.5); } } }
  &.button_dark {
    background-color: $dark;
    color: $white;
    &:focus {
      color: $white; }
    &:hover {
      border-color: $dark; }
    &.button_shadow {
      box-shadow: 0px 0px 30px 0px rgba(26, 26, 26, 0.2); } }
  &.button_light {
    background-color: $light;
    color: $dark;
    &:focus {
      color: $dark; }
    &:hover {
      border-color: $light; }
    &.button_shadow {
      box-shadow: 0px 0px 30px 0px rgba(245, 245, 245, 0.2); } }
  &.button_black {
    background-color: $black;
    color: $white;
    &:focus {
      color: $white; }
    &:hover {
      border-color: $dark; }
    &.button_shadow {
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2); } }
  &.button_white {
    background-color: $white;
    color: $accent;
    &:focus {
      color: $accent; }
    &:hover {
      border-color: $dark;
      background-color: $dark;
      color: $white; }
    &.button_shadow {
      box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.2); } } }
.button_fullwidth {
  width: 100%; }
.button_circle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  padding: 0; }
.button__text {}
.button__text_line {}
.button__text_line-right {
  &:after {
    content: '';
    display: inline-block;
    width: 43px;
    height: 1px;
    vertical-align: middle;
    margin-left: 12px;
    margin-right: -14px; } }

@media only screen and (max-width: $xl) {
  .button:not(.button_arrow, .button_circle) {
    padding: 14px 35px;
    font-size: 12px; } }

@media only screen and (max-width: $md) {
  .button__icon {
    max-height: 30px; } }


/* ======================================================================== */
/* 13. comments */
/* ======================================================================== */
.comments-area, .comment-respond {}
.comment-list {
  @include reset-ul;
  li {
    padding-top: 30px;
    padding-bottom: 30px; }
  > li:not(:last-child) {
 }    // border-bottom: 1px solid $borders
  ol {
    @include reset-ul;
    padding-left: 4%;
    li:last-child {
      padding-bottom: 0; } } }
.comments-title, .comment-reply-title {
  font-family: $font-secondary;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 0; }
.comments-title {
  margin-bottom: 1em; }
.comment-reply-title {
  margin-top: 80px;
  margin-bottom: 1.5em; }
.comment-meta {
  display: flex;
  justify-content: space-between;
  align-items: center; }
.comment-meta__wrapper-author {
  display: flex;
  align-items: center; }
.comment-metadata {
  margin-left: 25px;
  b {
    display: block;
    a {
      font-family: $font-secondary;
      font-size: 15px;
      line-height: 1.5;
      font-weight: bold;
      color: $black; } }
  time {
    display: block;
    font-size: 13px;
    color: $gray2;
    margin-top: 5px; } }
.comment-content {
  padding-left: 107px;
  margin-top: -1em; }
.comment-reply-link {
  padding: 10px 20px !important;
  color: $black !important;
  &:hover {
    color: $white !important; } }

@media only screen and (max-width: $xl) {
  .comments-title, .comment-reply-title {
    font-size: 25px; } }

@media only screen and (max-width: $sm) {
  .comment-content {
    margin-top: 0;
    padding-left: 0; }
  .comment-metadata {
    margin-left: 10px; }
  .comment-list li {
    padding-top: 15px;
    padding-bottom: 15px; }
  .comments-title, .comment-reply-title {
    font-size: 22px; }
  .comment-reply-title {
    margin-top: 40px;
    margin-bottom: 15px; } }

/* ======================================================================== */
/* 14. container */
/* ======================================================================== */
.container-fluid_50 {
  padding-left: 50px;
  padding-right: 50px; }

@media only screen and (max-width: $xl) {
  .container-fluid_50 {
    padding-left: 30px;
    padding-right: 30px; } }

@media only screen and (max-width: $sm) {
  .container-fluid_50 {
    padding-left: 15px;
    padding-right: 15px; } }

/* ======================================================================== */
/* 15. copyright */
/* ======================================================================== */
.copyright {
  display: block;
  font-size: 13px; }

/* ======================================================================== */
/* 16. counter */
/* ======================================================================== */
.counter {
	text-align: center; }
.counter__number {
	font-size: 46px;
	font-weight: bold;
	font-family: $font-secondary; }
.counter__label {
	margin-top: 5px; }

@media only screen and (max-width: $xl) {
	.counter__number {} }

/* ======================================================================== */
/* 17. figureContact */
/* ======================================================================== */
.figure-contact {
  .figure-contact__item:last-of-type {
    margin-bottom: 0; } }
.figure-contact__item {
  margin-top: 10px;
  margin-bottom: 40px; }
.figure-contact__property {
  display: block;
  font-size: 26px;
  line-height: 1.3; }
.figure-contact__value {
  display: block;
  font-size: 13px;
  margin-top: 8px; }

/* ======================================================================== */
/* 18. figureFeature */
/* ======================================================================== */
.figure-feature {
	max-width: 300px; }
.figure-feature__icon {
	font-size: 30px;
	color: $accent; }
.figure-feature__content {
	h3 {
		font-size: 18px;
		font-weight: bold;
		font-family: $font-secondary;
		margin-top: 20px;
		margin-bottom: 0; }
	p {
		margin-top: 10px;
		margin-bottom: 0; } }

@media only screen and (max-width: $xs) {
	.figure-feature {
		max-width: 100%; } }

/* ======================================================================== */
/* 19. figureMember */
/* ======================================================================== */
.figure-member {
	text-align: center;
	&:hover {
		.figure-member__overlay {
			transform: scaleY(1); }
		.figure-member__social {
			.social__item {
				transform: translateY(0px);
				opacity: 1;
				visibility: visible;
				@for $i from 1 through 10 {
					&:nth-child(#{$i}) {
						transition-delay: 300ms + $i * 30ms; } } } }
		.figure-member__line {
			transform: scaleX(1) {
      transition-delay: 300ms; } }
		.figure-member__content p {
			opacity: 1;
			visibility: visible;
			transform: translateY(0px);
			transition-delay: 200ms; } } }
.figure-member__name {
	font-family: $font-secondary;
	font-weight: bold;
	font-size: 18px; }
.figure-member__position {}
.figure-member__wrapper-img {
	position: relative;
	img {
		position: relative;
		z-index: -2; } }
.figure-member__wrapper-info {
	margin-top: 30px; }
.figure-member__hover-content {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
 }	// z-index: 50
.figure-member__content {
	padding: 30px;
	position: relative;
	z-index: 50;
	color: $white;
	p {
		@include trans1;
		opacity: 0;
		visibility: hidden;
		transform: translateY(20px); } }
.figure-member__line {
	width: 100%;
	height: 1px;
	background-color: $white;
	margin-top: 1em;
	margin-bottom: 1em;
	transform: scaleX(0);
	@include trans2; }
.figure-member__social {
	.social__icon {
		background-color: rgba(24, 24, 24, 0.4); }
	.social__item {
		margin: 6px;
		@include trans1;
		opacity: 0;
		visibility: hidden;
		transform: translateY(20px); } }
.figure-member__overlay {
	@include trans1;
	transform: scaleY(0);
	transform-origin: center bottom; }

@media only screen and (max-width: $md) {
	.figure-member__wrapper-info {
		margin-top: 20px; } }

/* ======================================================================== */
/* 20. figurePost */
/* ======================================================================== */
.figure-post {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  // background: $white
  padding-bottom: 30px;
  z-index: 50;
  &.bg-accent {
    color: $white;
    .post-meta__link {
      color: $white;
      &:hover {
        color: $gray; } } } }
.figure-post_gallery {
  padding-bottom: 0;
  .figure-post__wrapper-content h3 a, .post-meta__item, .post-meta__item-text {
    color: $white; }
  p {
    color: $white; }
  .post-meta__link {
    color: $white; }
  .figure-post__inner {
    position: absolute;
    left: 0;
    bottom: 50px;
    height: auto; } }
.figure-post_quote {
  padding: 30px 0;
  blockquote {
    font-size: 20px;
    line-height: 1.5;
    cite {
      display: block;
      font-family: $font-secondary;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      margin-top: 20px; } }
  // .figure-post__inner
  //   height: 100%
  .figure-post__footer {
    margin-top: 0; }
  &.bg-accent {
    .figure-post__wrapper-quote, .post-meta__item, .post-meta__item-text {
      color: $white; } } }
.figure-post__sign {
  font-size: 32px;
  text-align: right;
  padding: 0 30px;
  // position: absolute
  // right: 0
 }  // bottom: 0
.figure-post__wrapper-quote {
  padding: 0 30px;
  margin-top: auto;
  margin-bottom: auto; }
.figure-post__inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }

.figure-post_shadow {
  box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.1);
  @include trans1;
  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.2);
    transform: translateY(-5px); } }
.figure-post[class*='bg-'] {
  .figure-post__wrapper-content {
    position: relative;
    padding: 0 30px; }
  .figure-post__footer {
    padding: 0 30px; } }
.figure-post__wrapper-thumbnail {
  display: block;
  // margin-bottom: 25px
  img {
    width: 100%; } }
.figure-post__wrapper-content {
  position: relative;
  z-index: 50;
  h3 {
    font-family: $font-secondary;
    font-size: 21px;
    line-height: 1.42857;
    font-weight: bold;
    margin-top: 25px;
    a {
      color: $black;
      &:hover {
        color: $accent; } } } }
.figure-post__footer {
  position: relative;
  z-index: 50;
  margin-top: auto; }

@media only screen and (max-width: $md) {
  .figure-post_gallery .figure-post__inner {
    position: relative;
    left: auto;
    bottom: auto; }
  .figure-post_gallery {
    padding-bottom: 30px;
    .figure-post__wrapper-content h3 a,
    .post-meta__item,
    .post-meta__item-text,
    .post-meta__link,
    p {
      color: $black; } }
  .figure-post_quote blockquote {
    margin-top: 0em;
    margin-bottom: 0em; } }



/* ======================================================================== */
/* 21. figurePricing */
/* ======================================================================== */
.figure-pricing {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $white;
  padding: 50px 35px;
  z-index: 50;
  h5 {
    font-size: 20px;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0; }
  &.text-center {
    .figure-pricing__cost {
      justify-content: center; } } }
.figure-pricing_bordered:not(.figure-pricing_featured) {
  border: 1px solid $light2; }
.figure-pricing_featured {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: 0;
    right: 0;
    background-color: $white;
    box-shadow: 0px 0px 29px 0px rgba(0, 0, 0, 0.1);
    z-index: -1; } }
.figure-pricing_transparent {
  background-color: rgba(0, 0, 0, 0.5);
  color: $white;
  .figure-pricing__label, .figure-pricing__feature {
    color: $white; }
  .figure-pricing__divider {
    background-color: rgba(255, 255, 255, .2); } }
.figure-pricing_transparent.figure-pricing_featured {
  background-color: transparent;
  &:before {
    background-color: rgba(0, 0, 0, 0.5); } }
.figure-pricing__cost {
  display: flex; }
.figure-pricing__cost-sign {
  font-size: 23px;
  margin-right: 7px;
  margin-top: 3px; }
.figure-pricing__amount {
  font-size: 64px;
  line-height: 1.1;
  font-weight: bold;
  font-family: $font-secondary;
  text-align: center; }
.figure-pricing__label {
  font-family: $font-primary;
  font-size: 13px;
  color: $gray2;
  font-weight: normal; }
.figure-pricing__header {
  margin-bottom: 30px; }
.figure-pricing__features {
  @include reset-ul; }
.figure-pricing__feature {
  display: block;
  color: $gray2;
  margin-top: 16px;
  margin-bottom: 16px; }
.figure-pricing__divider {
  width: 100%;
  height: 1px;
  background-color: $light2;
  margin-top: 35px;
  margin-bottom: 19px; }
.figure-pricing__feature_yes {
  &:before {
    content: '\4e';
    @extend .elegant-icons;
    color: #9bca7d;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; } }
.figure-pricing__feature_no {
  &:before {
    content: '\4d';
    @extend .elegant-icons;
    color: $gray2;
    font-size: 17px;
    vertical-align: middle;
    margin-right: 6px; } }
.figure-pricing__wrapper-button {
  margin-top: auto;
  padding-top: 35px; }
.figure-pricing__feature-label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: $accent2;
  color: $white;
  display: inline-block;
  position: absolute;
  top: -25px;
  left: 0;
  padding: 5px 15px 5px 15px;
  z-index: 50;
  &:before, &:after {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid; }
  &:before {
    top: 0;
    border-width: 13px 13px 0 0;
    border-color: $accent2 transparent transparent transparent; }
  &:after {
    bottom: 0;
    border-width: 13px 0 0 13px;
    border-color: transparent transparent transparent $accent2; } }

@media only screen and (max-width: $xl) {
  .figure-pricing {
    padding: 50px 30px; }
  .figure-pricing_featured {
    &:before {
      top: -25px;
      bottom: -25px; } }
  .figure-pricing__feature-label {
    top: 0; }
  .figure-pricing__header {
    margin-bottom: 20px; } }

@media only screen and (max-width: $sm) {
  .figure-pricing_featured {
    margin-top: 25px;
    margin-bottom: 25px;
    height: auto; } }

/* ======================================================================== */
/* 22. figureProcess */
/* ======================================================================== */
.figure-process {
  position: relative;
  text-align: center;
  box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.1);
  background-color: $white;
  padding: 40px 30px 40px;
  @include trans1;
  height: 100%;
  h4 {
    font-size: 18px;
    margin-top: 1.5em;
    margin-bottom: 0; }
  p {
    margin-bottom: 0; }
  &:hover {
    box-shadow: 0px 10px 30px 0px rgba(20, 20, 20, 0.2);
    transform: translateY(-5px); } }
.figure-process__wrapper-icon {
  height: 120px;
  img {
    width: auto;
    height: 100%; } }
.figure-process__counter {
  position: absolute;
  left: 20px;
  bottom: 15px;
  font-size: 13px;
  font-weight: bold; }

/* ======================================================================== */
/* 23. figureService */
/* ======================================================================== */
.figure-service {
  position: relative;
  padding: 125px 15px 175px 15px;
  height: 100%;
  z-index: 50;
  @include trans1;
  h3 {
    font-size: 21px;
    line-height: 1.42857;
    margin-top: 0;
    margin-bottom: 1.25em;
    font-family: $font-secondary; } }
.figure-service__counter {
  font-size: 16px;
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 12px; }
.figure-service__headline {
  width: 45px;
  height: 1px;
  background-color: $white;
  margin-top: 1.25em;
  margin-bottom: 1.7em; }
.figure-service__wrapper-button {
  display: inline-block;
  position: absolute;
  right: 12px;
  bottom: 28px; }
.figure-service__icon {
  font-size: 64px;
  margin-bottom: 15px;
  &:before {
    margin-left: 0 !important;
    margin-right: 0 !important; } }

// @media only screen and (max-width: $xxl)
//   .figure-service
//     padding: 105px 15px 105px 15px

@media only screen and (max-width: $xl) {
  .figure-service {
    padding: 80px 15px 130px 15px; } }

/* ======================================================================== */
/* 24. filter */
/* ======================================================================== */
.filter {
  padding-bottom: 0 !important; }
.filter__item {
  padding: 10px 30px;
  h4 {
    font-family: $font-primary;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    font-size: 15px !important;
    letter-spacing: 0.5px; }
  &.tabs__item_active {
    color: $accent;
    &:hover {
      color: $accent; } } }
.filter__underline {
  background-color: $accent !important; }

@media only screen and (max-width: $sm) {
  .filter__item {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

/* ======================================================================== */
/* 25. footer */
/* ======================================================================== */
.footer {
  padding-top: 135px;
  padding-bottom: 135px; }
.footer__wrapper-widget {
  padding-top: 25px;
  padding-bottom: 25px; }

@media only screen and (max-width: $xxl) {
  .footer {
    padding-top: 95px;
    padding-bottom: 95px; } }

@media only screen and (max-width: $xl) {
  .footer {
    padding-top: 55px;
    padding-bottom: 55px; }
  .footer__wrapper-widget {
    padding-top: 15px;
    padding-bottom: 15px; } }

@media only screen and (max-width: $sm) {
  .footer {
    padding-top: 45px;
    padding-bottom: 45px; } }

/* ======================================================================== */
/* 26. form */
/* ======================================================================== */
.form {
  .form__row:last-child {
    .form__col {
      margin: 12px 0 0; } } }
.form__col {
  margin: 12px 0; }
.form__header {
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px; }
  margin-bottom: 15px; }

/* ======================================================================== */
/* 27. formControl */
/* ======================================================================== */
.form-control {
  display: block; }
.form-control_inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }
.form-control_inline-textarea {
  align-items: flex-start; }
.form-control__label {
  display: block;
  font-family: $font-secondary;
  font-size: 15px;
  margin-bottom: 10px; }
.form-control__label_inline {
  flex: 1 1 auto;
  margin-bottom: 0; }
.form-control__input {
  display: block;
  width: 100%;
  outline: none;
  background-color: transparent;
  color: inherit;
  border-radius: 25px;
  background: $white;
  border: none;
  padding: 12px 24px;
  border: 1px solid transparent;
  @include trans1;
  &:focus {
    border-color: $accent; } }
.form-control__input_semi-transparent {
  background: rgba(0, 0, 0, .5); }
.form-control__input_light {
  background-color: $light; }
.form-control__input_textarea {
  resize: none;
  height: 250px; }
.form-control__wrapper-input {
  position: relative;
  max-width: 430px;
  width: 100%; }
.form-control__input_inline {}
.form-control__label_inline-textarea {
  margin-top: 12px; }
.form-control__error {
  color: inherit;
  font-size: 10px;
  font-weight: bold;
  color: red; }

 ::-webkit-input-placeholder {
  color: $gray; }
 ::-moz-placeholder {
  color: $gray; }
 :-moz-placeholder {
  color: $gray; }
 :-ms-input-placeholder {
  color: $gray; }

@media only screen and (max-width: $md) {
  .form-control_inline {
    display: block; }
  .form-control__label_inline {
    margin-bottom: 15px; }
  .form-control__label_inline-textarea {
    margin-top: 0; } }

/* ======================================================================== */
/* 28. gmap */
/* ======================================================================== */
.gmap {
  width: 100%;
  height: 100%;
  background-color: $light; }
.gmap__container {
  width: 100%;
  height: 100%;
  min-height: 900px; }

@media only screen and (max-width: $md) {
  .gmap__container {
    min-height: 600px; } }

/* ======================================================================== */
/* 29. grid */
/* ======================================================================== */
.grid {
 }  // width: 100%
.grid_30 {
  margin: -15px; }
.grid_5 {
  margin: -2.5px; }
.grid__item, .grid__sizer {
  display: block;
  img {
    width: 100%;
    position: relative;
    z-index: -2; } }
.grid__sizer {
  padding: 0 !important;
  height: 0px !important; }
.grid__item_30 {
  padding: 15px; }
.grid__item_5 {
  padding: 2.5px; }
.grid__item-link {
  position: relative;
  display: block;
  text-decoration: none;
  &:hover {
    .grid__item-info {
      h3 {
        opacity: 1;
        visibility: visible;
        transform: translateY(0px);
        &:after {
          transform: scaleX(1);
          transition-delay: 100ms; } }
      .grid__item-category {
        opacity: 1;
        visibility: visible;
        transition-delay: 200ms;
        transform: translateY(0px); } }
    .grid__item-overlay {
      transform: scaleY(1); } } }
.grid__item-info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $white;
  z-index: 50;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h3 {
    position: relative;
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    letter-spacing: 0.5px;
    @include trans1;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: $white;
      margin-bottom: 30px;
      @include trans2;
      transform: scaleX(0); } } }
.grid__item-category {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  @include trans1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px); }
.grid__item-overlay {
  transform: scaleY(0);
  transform-origin: center bottom;
  @include trans1; }

@media only screen and (max-width: $xl) {
  .grid__item-info {
    h3 {
      font-size: 24px;
      &:after {
        margin-bottom: 15px; } } }
  .grid__item-category {
    font-size: 12px; } }

/* ======================================================================== */
/* 30. header */
/* ======================================================================== */
.header {
	.social__icon {
		padding: 5px;
		background-color: rgba(24, 24, 24, 0.2);
		width: 40px !important;
		height: 40px !important;
		font-size: 14px; } }
.header__container {
	padding-left: 50px;
	padding-right: 50px;
	padding-top: 25px;
	padding-bottom: 25px; }
.header__row {
	margin-left: -25px;
	margin-right: -25px; }
.header__col {
	padding-left: 25px;
	padding-right: 25px; }
.header__wrapper-left {}
.header__wrapper-center {}
.header__wrapper-right {
	margin-left: auto; }
.header_absolute {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	z-index: 500;
	&.header_white {
		.menu {
			> li {
				> a {
					color: $white; } }
			> li:not(.menu-item-has-children) {
				> a {
					&:before {
						background-color: $white; }
					&:hover {
						color: $white; } } } }
		.burger__line {
			background-color: $white; }
		.burger.burger_opened {
			.burger__line {
				background-color: $white; } } } }
.header__wrapper-overlay-menu {
	display: flex;
	flex-wrap: wrap;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 500;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	padding-top: 120px;
	padding-bottom: 120px;
	opacity: 0;
	visibility: hidden;
	will-change: opacity, visibility; }
.header__overlay {
	z-index: -1;
	opacity: .6 !important; }
.header__diagonal-curtain-1, .header__diagonal-curtain-2 {
	position: absolute;
	width: 3000px;
	height: 3000px;
	z-index: 0; }
.header__diagonal-curtain-1 {
	bottom: 100%;
	// right: 200px
	right: 20%;
	transform: rotate(-49deg);
	transform-origin: bottom right;
	background-color: $black; }
.header__diagonal-curtain-2 {
	bottom: 0%;
	// right: 200px
	left: 80%;
	// right: 20%
	transform: rotate(-49deg);
	transform-origin: bottom right;
	background-color: $black; }
.header__wrapper-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow: hidden; }
.header__overlay-menu-back {
	position: fixed;
	top: 65px;
	left: 50px;
	z-index: 100;
	color: $white;
	font-size: 30px;
	cursor: pointer; }
.header__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -2;
	// +trans2
	// opacity: 0
	// visibility: hidden
	// transform: scale(1.1)
	will-change: opacity, visibility, transform; }
// .header__background_active
// 	transform: scale(1)
// 	opacity: 1
// 	visibility: visible
.header__wrapper-overlay-widgets {
	margin: 15px 0;
	text-align: center;
	width: 100%; }

@media only screen and (max-width: $xl) {
	//
	.header__container {
		padding-left: 30px;
		padding-right: 30px; }
	.header__overlay-menu-back {
		top: 45px;
		left: 30px; }
	.header__wrapper-overlay-menu {
		padding-top: 100px;
		padding-bottom: 100px; } }

@media only screen and (max-width: $md) {
	.header {
		.logo {
			height: 60px; } } }

@media only screen and (max-width: $sm) {
	//
	.header__container {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 15px;
		padding-bottom: 15px; }
	.header__row {
		margin-left: -15px;
		margin-right: -15px; }
	.header__col {
		padding-left: 15px;
		padding-right: 15px; }
	.header__overlay-menu-back {
		top: 32px;
		left: 15px; } }

/* ======================================================================== */
/* 31. link */
/* ======================================================================== */
.link {
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  color: inherit;
  &:hover {
    color: $accent; } }
.link__text {
  display: inline-block;
  vertical-align: middle; }
.link__icon {
  display: inline-block;
  vertical-align: middle; }

/* ======================================================================== */
/* 32. logo */
/* ======================================================================== */
.logo {
	display: block;
	height: 110px;
	img {
		width: auto;
		height: 100%; } }

@media only screen and (max-width: $xl) {
	.logo {
		height: 80px; } }


/* ======================================================================== */
/* 33. menu */
/* ======================================================================== */
.menu {
  @include reset-ul;
  width: 100%;
  > li {
    position: relative;
    display: inline-block;
    margin-right: 42px;
    > a {
      display: block;
      color: $dark;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.1px;
      line-height: 2;
      &:hover {
        color: $dark; } } }
  > li:last-child {
    margin-right: 0; }
  > li.menu-item-has-children {
    > a {
      &:after {
        content: '\f107';
        font-family: 'FontAwesome';
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px; }
      &:hover {
        ~ ul {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0); } } } }
  > li:not(.menu-item-has-children) {
    > a {
      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 1px;
        background-color: $dark;
        bottom: 0;
        right: 1px;
        @include trans1; }
      &:hover {
        color: $dark;
        &:before {
          left: 1px;
          width: calc(100% - 1px); } } } } }
.menu .sub-menu {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  @include reset-ul;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  @include trans1;
  transform: translate(0, 10px);
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0px 3px 14px 0px rgba(162, 162, 162, 0.65);
  z-index: 50;
  > li {
    position: relative;
    display: block;
    white-space: nowrap;
    a {
      display: flex;
      align-items: center;
      padding: 10px 30px;
      color: $dark;
      &:hover {
        color: $accent; } } }
  > li.menu-item-has-children {
    > a {
      &:after {
        content: '\f105';
        font-family: 'FontAwesome';
        font-size: 14px;
        display: inline-block;
        vertical-align: middle;
        margin-left: auto;
        margin-top: 2px;
        padding-left: 20px; }
      &:hover {
        ~ ul {
          opacity: 1;
          visibility: visible;
          transform: translate(0, 0); } } } }
  ul {
    top: -10px;
    left: calc(100% - 10px);
    transform: translate(10px, 0);
    width: 100%; }
  &:hover {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0); } }

/* ======================================================================== */
/* 34. menuOverlay */
/* ======================================================================== */
.menu-overlay {
	position: relative;
	margin-top: auto;
	margin-bottom: auto;
	list-style-type: none;
	text-align: center;
	z-index: 50;
	> li {
		display: block;
		margin: 20px 0;
		> a {
			position: relative;
			display: block;
			overflow: hidden;
			color: $white;
			font-size: 40px;
			font-weight: bold;
			&:hover {
				color: $gray; } } }
	> li:not(.menu-item-has-children) {
		> a {
			span {
				display: inline-block;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					width: 0;
					height: 1px;
					background-color: $white;
					bottom: 0;
					right: 5px;
					@include trans1; } }
			&:hover {
				color: $white;
				span {
					&:before {
						left: 5px;
						width: calc(100% - 5px); } } } } } }
.menu-overlay__item-wrapper {}
.menu-overlay {
	.sub-menu {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include reset-ul;
		z-index: 100;
		> li {
			display: block;
			> a {
				display: block;
				position: relative;
				font-size: 24px;
				font-weight: bold;
				overflow: hidden;
				color: $white;
				span {
					display: inline-block; }
				.menu-overlay__item-wrapper {
					padding: 10px 15px; }
				&:hover {
					color: $gray; } } }
		> li:not(.menu-item-has-children) {
			> a {
				&:hover {
					color: $white; }
				.menu-overlay__item-wrapper {
					&:hover {
						&:before {
							left: 15px;
							width: calc(100% - 30px); } }
					&:before {
						content: '';
						position: absolute;
						width: 0;
						height: 1px;
						background-color: $white;
						bottom: 10px;
						right: 15px;
						@include trans1; } } } } } }

@media only screen and (max-width: $sm) {
	.menu-overlay {
		> li {
			> a {
				font-size: 30px; } }
		.sub-menu {
			> li {
				> a {
					font-size: 18px; } } } } }

/* ======================================================================== */
/* 35. ofi */
/* ======================================================================== */
.of-cover {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }
.of-contain {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

/* ======================================================================== */
/* 36. overlay */
/* ======================================================================== */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  will-change: transform; }
.overlay_dark {
  background-color: $dark;
  opacity: .65;
  mix-blend-mode: multiply; }
.overlay_black {
  background-color: $black;
  opacity: .65; }
.overlay_white {
  background-color: $white;
  opacity: .65; }
.overlay_white-solid {
  background-color: $white;
  opacity: 1; }
.overlay_accent {
  background-color: $accent;
  opacity: 0.95;
  mix-blend-mode: multiply; }
.overlay_accent-normal {
  background-color: $accent;
  opacity: .75; }

/* ======================================================================== */
/* 37. pagination */
/* ======================================================================== */
.pagination {}

/* ======================================================================== */
/* 38. postMeta */
/* ======================================================================== */
.post-meta {
  @include reset-ul;
  margin: 0 -10px; }
.post-meta__item {
  display: inline-block;
  margin: 0 10px;
  font-size: 13px; }
.post-meta__item-text {
  color: $gray2; }
.post-meta__link {
  display: inline-block;
  vertical-align: middle;
  color: $black;
  &:hover {
    color: $accent; } }
.post-meta__item-icon {
  display: inline-block;
  vertical-align: middle; }
.post-meta__item-text {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px; }

/* ======================================================================== */
/* 39. preloader */
/* ======================================================================== */
.preloader {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999;
	overflow: hidden; }
.preloader__curtain {
	position: absolute;
	top: 0;
	left: 100%;
	width: 3000px;
	height: 3000px;
	transform: skew(45deg);
	transform-origin: bottom right;
	&:nth-child(1) {
		z-index: 0; }
	&:nth-child(2) {
		z-index: 1; }
	&:nth-child(3) {
		z-index: 2; } }
.preloader__progress {
	position: relative;
	width: 100%;
	height: 2px;
	background-color: $dark;
	margin-left: auto;
	margin-right: auto;
	max-width: 320px;
	z-index: 50; }
.preloader__progress-fill {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $white;
	transform: scaleX(0);
	transform-origin: left center; }

@media only screen and (max-width: $sm) {
	.preloader__progress {
		max-width: 270px; } }

/* ======================================================================== */
/* 40. properties */
/* ======================================================================== */
.properties {
  @include reset-ul;
  margin: 0 -25px; }
.properties__item {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 25px; }
.properties__name {
  display: inline-block;
  font-size: 13px;
  line-height: 1.538;
  font-weight: bold;
  text-transform: uppercase; }
.properties__value {
  display: inline-block;
  font-size: 14px;
  color: $gray2;
  margin-left: 10px; }

@media only screen and (max-width: $sm) {
  .properties__item {
    display: block;
    width: 100%; } }
@media only screen and (max-width: $md) {
  .properties {
    margin: 0 -15px; }
  .properties__item {
    margin: 10px 15px; }
  .post__properties .properties {
    flex-wrap: wrap; } }

/* ======================================================================== */
/* 41. scrollDown */
/* ======================================================================== */
.scroll-down {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  @include trans1;
  &:hover {
    transform: translateY(5px); } }
.scroll-down__label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.24px;
  margin-bottom: 14px; }
.scroll-down__icon {
  display: inline-block;
  width: 40px;
  img {
    width: 100%; } }

@media only screen and (max-width: $md) {
  .scroll-down__icon {
    width: 30px; } }

/* ======================================================================== */
/* 42. scrollUp */
/* ======================================================================== */
.scroll-up {
	position: fixed;
	right: 50px;
	bottom: 50px;
	z-index: 500; }
.scroll-up__icon {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 60px;
	height: 60px;
	background-color: $accent;
	border-radius: 100%;
	color: $white;
	font-size: 24px;
	@include trans1;
	&:hover {
		background-color: $dark; } }

@media only screen and (max-width: $md) {
	.scroll-up {
		width: 50px;
		height: 50px;
		right: 30px;
		bottom: 30px;
		font-size: 20px; } }

/* ======================================================================== */
/* 43. section */
/* ======================================================================== */
.section {
  position: relative; }
.section_pt {
  padding-top: 160px; }
.section_pt-small {
  padding-top: 70px; }
.section_pt-large {
  padding-top: 200px; }
.section_pb {
  padding-bottom: 160px; }
.section_pb-small {
  padding-bottom: 70px; }
.section_pb-large {
  padding-bottom: 200px; }
.section_mt {
  margin-top: 160px; }
.section_mt-small {
  margin-top: 70px; }
.section_mt-large {
  margin-top: 200px; }
.section_mb {
  margin-bottom: 160px; }
.section_mb-small {
  margin-bottom: 70px; }
.section_mb-large {
  margin-bottom: 200px; }
.section-fullheight {
  display: flex; }
.section-fullheight__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh; }
.section__header {
  position: relative;
  margin-bottom: 60px;
  z-index: 50;
  h2 {
    font-family: $font-secondary;
    font-size: 46px;
    margin-top: 0;
    margin-bottom: 0.5em;
    line-height: 1.3; }
  p {
    margin-bottom: 0; } }
.section__content {
  position: relative;
  z-index: 50; }


@media only screen and (max-width: $xxl) {
  //
  .section_pt {
    padding-top: 120px; }
  .section_pt-small {
    padding-top: 50px; }
  .section_pt-large {
    padding-top: 160px; }
  .section_pb {
    padding-bottom: 120px; }
  .section_pb-small {
    padding-bottom: 50px; }
  .section_pb-large {
    padding-bottom: 160px; }
  .section_mt {
    margin-top: 120px; }
  .section_mt-small {
    margin-top: 50px; }
  .section_mt-large {
    margin-top: 160px; }
  .section_mb {
    margin-bottom: 120px; }
  .section_mb-small {
    margin-bottom: 50px; }
  .section_mb-large {
    margin-bottom: 160px; }
  //
  .section__header {
    margin-bottom: 40px;
    h2 {
      font-size: 34px; } } }

@media only screen and (max-width: $xl) {
  //
  .section_pt {
    padding-top: 80px; }
  .section_pt-small {
    padding-top: 40px; }
  .section_pt-large {
    padding-top: 100px; }
  .section_pb {
    padding-bottom: 80px; }
  .section_pb-small {
    padding-bottom: 40px; }
  .section_pb-large {
    padding-bottom: 100px; }
  .section_mt {
    margin-top: 80px; }
  .section_mt-small {
    margin-top: 40px; }
  .section_mt-large {
    margin-top: 100px; }
  .section_mb {
    margin-bottom: 80px; }
  .section_mb-small {
    margin-bottom: 40px; }
  .section_mb-large {
    margin-bottom: 100px; } }

@media only screen and (max-width: $sm) {
  //
  .section_pt {
    padding-top: 60px; }
  .section_pt-small {
    padding-top: 30px; }
  .section_pt-large {
    padding-top: 80px; }
  .section_pb {
    padding-bottom: 60px; }
  .section_pb-small {
    padding-bottom: 30px; }
  .section_pb-large {
    padding-bottom: 80px; }
  .section_mt {
    margin-top: 60px; }
  .section_mt-small {
    margin-top: 30px; }
  .section_mt-large {
    margin-top: 80px; }
  .section_mb {
    margin-bottom: 60px; }
  .section_mb-small {
    margin-bottom: 30px; }
  .section_mb-large {
    margin-bottom: 80px; } }

/* ======================================================================== */
/* 44. section404 */
/* ======================================================================== */
.section-404__big {
	font-size: 350px;
	line-height: 0.9;
	font-weight: bold;
	font-family: $font-secondary; }
.section-404__header {
	h1 {
		font-size: 46px;
		font-weight: bold; }
	p {
		font-size: 16px; } }
.section-404__wrapper-button {
	margin-top: 80px; }

@media only screen and (max-width: $xl) {
	.section-404__big {
		font-size: 262px; }
	.section-404__header {
		h1 {
			font-size: 34px; } }
	.section-404__wrapper-button {
		margin-top: 40px; } }

@media only screen and (max-width: $sm) {
	.section-404__big {
		font-size: 130px;
		margin-bottom: 20px; }
	.section-404__header {
		h1 {
			font-size: 24px; } }
	.section-404__wrapper-button {
		margin-top: 30px; } }

/* ======================================================================== */
/* 45. sectionBlog */
/* ======================================================================== */
.section-blog__wrapper-button {
  margin-top: 40px; }
.section-blog__wrapper-post {
  margin-bottom: 30px; }

@media only screen and (max-width: $xxl) {
  .section-blog__wrapper-button {
    margin-top: 30px; } }

/* ======================================================================== */
/* 46. sectionAbout */
/* ======================================================================== */
.section-about__header {
	h2 {
		font-size: 60px;
		line-height: 1.2;
		margin-bottom: 40px; }
	p {
		&:last-child {
			margin-bottom: 0; } } }
.section-about__wrapper-subheading {
  margin-bottom: 40px;
  span {
    display: block;
    font-size: 13px;
    line-height: 1.538;
    font-weight: bold;
    text-transform: uppercase;
    &:after {
      content: '';
      display: block;
      width: 48px;
      height: 1px;
      background-color: $black;
      margin-top: 14px; } } }
.section-about__wrapper-image {
  text-align: center; }

@media only screen and (max-width: $xl) {
  .section-about__header {
    h2 {
      font-size: 34px; } } }

@media only screen and (max-width: $md) {
  .section-about__wrapper-image {
    margin-top: 50px; } }

/* ======================================================================== */
/* 47. sectionCTA */
/* ======================================================================== */
.section-cta {
  h2 {
    margin-top: 0; } }
.section-cta__wrapper-button {
  margin-top: 40px; }


@media only screen and (max-width: $xl) {
  .section-cta {
    h2 {
      font-size: 45px; } } }

@media only screen and (max-width: $sm) {
  .section-cta {
    h2 {
      font-size: 33px; } }
  .section-cta__wrapper-button {
    margin-top: 30px; } }

/* ======================================================================== */
/* 48. sectionContacts */
/* ======================================================================== */
.section-contacts {
	&.color-white {
		.figure-contact {
			a {
				color: $white;
				&:hover {
					color: $white;
					opacity: .5; } } } } }
@media only screen and (max-width: $md) {
	.section-contacts__wrapper-contacts {
		margin-top: 40px; } }

/* ======================================================================== */
/* 49. sectionFeatures */
/* ======================================================================== */
.section-features {
	&.section_mb {
		margin-bottom: 100px; } }
.section-features__wrapper-item {
	margin-bottom: 60px; }

@media only screen and (max-width: $xl) {
	.section-features {
		&.section_mb {
			margin-bottom: 60px; } }
	.section-features__wrapper-item {
		margin-bottom: 40px; } }

/* ======================================================================== */
/* 50. sectionIntro */
/* ======================================================================== */
.section-intro {
  overflow: hidden;
  h1 {
    font-family: $font-secondary;
    font-size: 60px;
    line-height: 1.2;
    font-weight: bold;
    letter-spacing: 0.5px;
    margin-top: 0;
    margin-bottom: 0.5em; }
  p {
    font-size: 20px; } }
.section-intro__wrapper-content {
  padding-left: 215px;
  padding-top: 160px;
  padding-bottom: 160px; }
.section-intro__wrapper-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }
.section-intro__wrapper-sd {
  position: absolute;
  display: inline-block;
  bottom: 18px;
  left: 38%;
  z-index: 60; }

.section-intro__diagonal-curtain-1, .section-intro__diagonal-curtain-2 {
  position: absolute;
  width: 3000px;
  height: 3000px; }
.section-intro__diagonal-curtain-1 {
  top: 100%;
  // right: 200px
  right: 20%;
  transform: rotate(45deg);
  transform-origin: top right;
  background-color: $accent; }
.section-intro__diagonal-curtain-2 {
  bottom: 100%;
  // right: 200px
  right: 20%;
  transform: rotate(-45deg);
  transform-origin: bottom right;
  background-color: $accent; }
.section-intro__diagonal-line-1, .section-intro__diagonal-line-2 {
  position: absolute;
  right: calc(20% - 32px);
  height: 10px;
  width: 3000px;
  background-color: $accent; }
.section-intro__diagonal-line-1 {
  bottom: calc(100% + 10px);
  transform: rotate(-45deg);
  transform-origin: top right; }
.section-intro__diagonal-line-2 {
  top: calc(100% + 10px);
  transform: rotate(45deg);
  transform-origin: bottom right; }


// $i: 500
// @while $i < 1700
//   $bp: $i
//   $val: 74.9 - $i / 1666
//   $i: $i + 100
//   @media only screen and (max-height: #{$bp}px)
//     .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//       width: calc(#{$val}vh)
// @media only screen and (max-height: 1700px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.1vh)
// @media only screen and (max-height: 1600px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.3vh)
// @media only screen and (max-height: 1500px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.5vh)
// @media only screen and (max-height: 1400px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.7vh)
// @media only screen and (max-height: 1300px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(72.9vh)
// @media only screen and (max-height: 1200px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.1vh)
// @media only screen and (max-height: 1100px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.3vh)
// @media only screen and (max-height: 1000px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.6vh)
// @media only screen and (max-height: 900px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(73.9vh)
// @media only screen and (max-height: 800px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.2vh)
// @media only screen and (max-height: 700px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.5vh)
// @media only screen and (max-height: 600px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(74.9vh)
// @media only screen and (max-height: 500px)
//   .section-intro__diagonal-line-1, .section-intro__diagonal-line-2
//     width: calc(75.3vh)

@media only screen and (max-width: $xxl) {
  .section-intro {
    h1 {
      font-size: 46px; }
    p {
      font-size: 18px; } }
  .section-intro__wrapper-content {
    padding-left: 50px; } }

@media only screen and (max-width: $md) {
  .section-intro__wrapper-content {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center; }
  .section-intro__wrapper-sd {
    left: 50%;
    transform: translateX(-50%); } }
@media only screen and (max-width: $sm) {
  .section-intro {
    h1 {
      font-size: 30px; }
    p {
      font-size: 16px; } } }

/* ======================================================================== */
/* 51. sectionLatestNews */
/* ======================================================================== */
.section-latest-news {
	&.section_pb {
		padding-bottom: 130px; } }

@media only screen and (max-width: $xxl) {
	.section-latest-news {
		&.section_pb {
			padding-bottom: 100px; } } }

@media only screen and (max-width: $xl) {
	.section-latest-news {
		&.section_pb {
			padding-bottom: 50px; } } }

@media only screen and (max-width: $sm) {
	.section-latest-news {
		&.section_pb {
			padding-bottom: 40px; } } }

/* ======================================================================== */
/* 52. sectionLatestProjects */
/* ======================================================================== */
.section-latest-projects__wrapper-content {
  position: relative;
  z-index: 50; }
.section-latest-projects__wrapper-backgrounds {
  position: absolute;
  top: 0;
  right: -50%;
  width: 100%;
  height: 100%;
  z-index: -1; }
.section-latest-projects__inner {
  position: relative;
  z-index: 50; }
.section-latest-projects__wrapper-tabs {
  padding-top: 140px;
  padding-bottom: 140px; }
.section-latest-projects__wrapper-button {
  padding-top: 70px;
  padding-bottom: 70px; }

@media only screen and (max-width: $xxl) {
  .section-latest-projects__wrapper-tabs {
    padding-top: 120px;
    padding-bottom: 120px; } }

@media only screen and (max-width: $xl) {
  .section-latest-projects__wrapper-tabs {
    padding-top: 100px;
    padding-bottom: 100px; } }

@media only screen and (max-width: $sm) {
  .section-latest-projects__wrapper-tabs {
    padding-top: 80px;
    padding-bottom: 40px; }
  // .section-latest-projects__wrapper-button
  //   padding-top: 40px
  //   padding-bottom: 40px
  .section-latest-projects__wrapper-button {
    padding-top: 60px;
    padding-bottom: 60px; } }

/* ======================================================================== */
/* 53. sectionMap */
/* ======================================================================== */
.section-map {
	z-index: 100; }
.section-map__wrapper-form {
	position: absolute;
	right: 50px;
	top: -150px;
	max-width: 510px;
	width: 100%;
	padding: 50px 50px 35px;
	background-color: $white;
	z-index: 100; }
.section-map__wrapper-info {
	position: absolute;
	left: 50px;
	top: 50%;
	max-width: 420px;
	width: 100%;
	transform: translateY(-50%);
	z-index: 100;
	background-color: $accent;
	padding: 50px 35px;
	.figure-contact {
		display: flex;
		flex-wrap: wrap; }
	.figure-contact__item {
		flex: 1 1 50%;
		margin-bottom: 30px;
		padding: 0 15px; }
	.figure-contact__property {
		font-size: 13px;
		font-weight: bold; }
	.figure-contact__value {
		font-size: 15px; } }

@media only screen and (max-width: $xxl) {
	.section-map__content {
		display: flex;
		flex-wrap: wrap; }
	.section-map__wrapper-form {
		position: relative;
		right: auto;
		top: auto;
		flex: 1 0 420px;
 }		// padding: 80px 50px 65px
	.section-map__wrapper-map {
		width: 100%;
		// max-width: calc(100% / 3 * 2)
		max-width: 100%;
		flex: 0 1 calc(100% - 420px); }
	.section-map__wrapper-info {
		position: relative;
		left: auto;
		top: auto;
		width: 100%;
		max-width: 100%;
		transform: none; }
	.section-map__wrapper-info {
		text-align: center;
		.figure-contact {
			justify-content: center; }
		.figure-contact__item {
			flex: 0 1 25%;
			margin-top: 15px;
			margin-bottom: 15px; } } }

@media only screen and (max-width: $md) {
	.section-map__wrapper-form {
		flex: 1 0 100%;
		max-width: 100%; }
	.section-map__wrapper-map {
		flex: 1 0 100%; } }

@media only screen and (max-width: $sm) {
	.section-map__wrapper-form {
		padding: 60px 30px; }
	.section-map__wrapper-info {
		.figure-contact__item {
			flex: 1 1 100%; } } }

/* ======================================================================== */
/* 54. sectionMasthead */
/* ======================================================================== */
.section-masthead {
  display: flex;
  h1:not(.section-masthead__subheading) {
    font-family: $font-primary;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1.5em; } }
.section-masthead__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 50;
  min-height: 500px; }
.section-masthead__header_background {
  padding-top: 250px;
  padding-bottom: 250px;
  min-height: 800px; }
.section-masthead__subheading {
  font-size: 60px;
  line-height: 1.3;
  font-family: $font-secondary; }


@media only screen and (max-width: $xxl) {
  .section-masthead__header_background {
    padding-top: 160px;
    padding-bottom: 160px;
    min-height: 700px; } }

@media only screen and (max-width: $xl) {
  .section-masthead__header_background {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 500px; }
  .section-masthead__subheading {
    font-size: 45px; } }

@media only screen and (max-width: $sm) {
  .section-masthead__header_background {
    padding-top: 120px;
    padding-bottom: 60px;
    min-height: 360px; }
  .section-masthead {
    h1 {
      font-size: 12px; } }
  .section-masthead__subheading {
    font-size: 33px; } }

/* ======================================================================== */
/* 55. sectionPortfolio */
/* ======================================================================== */
.section-portfolio__wrapper-tabs {
  margin-bottom: 40px; }
.section-portfolio__wrapper-button {
  margin-top: 70px; }

@media only screen and (max-width: $md) {
  .section-portfolio__wrapper-tabs {
    margin-bottom: 30px; } }

/* ======================================================================== */
/* 56. sectionPricing */
/* ======================================================================== */
.section-pricing__wrapper-item {
	margin-top: 50px;
	margin-bottom: 50px; }

@media only screen and (max-width: $xl) {
	.section-pricing__wrapper-item {
		margin-top: 40px;
		margin-bottom: 40px; } }

@media only screen and (max-width: $sm) {
	.section-pricing__wrapper-item {
		margin-top: 15px;
		margin-bottom: 15px; } }

/* ======================================================================== */
/* 57. sectionProcess */
/* ======================================================================== */
.section-process {
	&.section_mb {
		margin-bottom: 130px; } }
.section-process__wrapper-item {
	margin-bottom: 30px; }

@media only screen and (max-width: $xxl) {
	.section-process {
		&.section_mb {
			margin-bottom: 90px; } } }

@media only screen and (max-width: $xl) {
	.section-process {
		&.section_mb {
			margin-bottom: 70px; } } }

@media only screen and (max-width: $sm) {
	.section-process {
		&.section_mb {
			margin-bottom: 50px; } } }

/* ======================================================================== */
/* 58. sectionServices */
/* ======================================================================== */
.section-services {}
.section-services__wrapper-service:last-of-type {
  box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0); }
.section-services__wrapper-service {
  // border-right: 1px solid rgba(255, 255, 255, .2)
  @include trans1;
  box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0.1);
  &:hover {
    box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0.62), 1px 0px 0px 0px rgba(255, 255, 255, 0);
    .section-services__service-overlay {
      opacity: .85; } } }
.section-services__service-overlay {
  @include trans1; }
.section-services__wrapper-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
.section-services__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@media only screen and (max-width: $xl) {
  .section-services__wrapper-service {
    box-shadow: 0px 15px 34px 0px rgba(5, 16, 37, 0), 1px 0px 0px 0px rgba(255, 255, 255, 0.1), 0px 1px 0px 0px rgba(255, 255, 255, 0.1); } }

/* ======================================================================== */
/* 59. sectionServicesTabs */
/* ======================================================================== */
.section-services-tabs {
	z-index: 100; }
.section-services-tabs__wrapper-tabs {
	margin-top: -160px;
	margin-bottom: 60px;
	padding-top: 100px;
	padding-bottom: 100px; }
.section-services-tabs__content {}

@media only screen and (max-width: $xxl) {
	.section-services-tabs__wrapper-tabs {
		padding-top: 80px;
		padding-bottom: 80px;
		margin-top: -140px; } }

@media only screen and (max-width: $xl) {
	.section-services-tabs__wrapper-tabs {
		padding-top: 60px;
		padding-bottom: 60px;
		margin-top: -120px;
		margin-bottom: 40px; } }

@media only screen and (max-width: $md) {
	.section-services-tabs__wrapper-tabs {
		margin-bottom: 30px; } }

@media only screen and (max-width: $sm) {
	.section-services-tabs__wrapper-tabs {
		padding-top: 25px;
		padding-bottom: 25px;
		margin-top: 0;
		max-width: 100%; } }

@media only screen and (min-width: 720px) {
	.section-services-tabs__wrapper-tabs {
		max-width: 690px; } }
@media only screen and (min-width: 992px) {
	.section-services-tabs__wrapper-tabs {
		max-width: 930px; } }
@media only screen and (min-width: 1200px) {
	.section-services-tabs__wrapper-tabs {
		max-width: 1110px; } }

/* ======================================================================== */
/* 60. sectionTeam */
/* ======================================================================== */
.section-team {
	&.section_mb {
		margin-bottom: 100px; } }
.section-team__wrapper-item {
	margin-bottom: 60px; }

@media only screen and (max-width: $xl) {
	.section-team {
		&.section_mb {
			margin-bottom: 60px; } }
	.section-team__wrapper-item {
		margin-bottom: 40px; } }
@media only screen and (max-width: $sm) {
	.section-team {
		&.section_mb {
			margin-bottom: 20px; } } }

/* ======================================================================== */
/* 61. sectionTestimonials */
/* ======================================================================== */

/* ======================================================================== */
/* 62. share */
/* ======================================================================== */
.share {
  display: inline-block;
  @include reset-ul; }
.share__item {
  display: inline-block; }
.share__icon {
  display: block;
  color: $gray2;
  padding: 6px; }

/* ======================================================================== */
/* 63. sidebar */
/* ======================================================================== */
.sidebar {}

/* ======================================================================== */
/* 64. slider */
/* ======================================================================== */
.slider {}
.slider-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -3px; }
.slider-nav__dot {
  position: relative;
  width: 16px;
  height: 16px;
  cursor: pointer;
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border-style: solid;
    border-width: 3.5px;
    border-color: $white;
    border-radius: 50%;
    @include trans1; }
  &:not(.slider-nav__dot_active):hover {
    &:after {
      opacity: .5; } } }
.slider-nav__dot_active {
  &:after {
    transform: translate(-50%, -50%) scale(1.5714);
    border-width: 1px; } }
.slider__prev, .slider__next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  border: 2px solid $white;
  border-radius: 50%;
  cursor: pointer;
  color: $white;
  @include trans1;
  &:after {
    font-family: 'ElegantIcons';
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 23px; }
  &:hover {
    background-color: $accent;
    border-color: $accent;
    color: $white; }
  &:focus {
    outline: none; }
  &.swiper-button-disabled {
    opacity: 0;
    visibility: hidden; } }
.slider__prev {
  &:after {
    content: '\23'; } }
.slider__next {
  &:after {
    content: '\24'; } }

@media only screen and (max-width: $xl) {
  .slider__prev, .slider__next {
    width: 40px;
    height: 40px;
    &:after {
      font-size: 16px; } } }
@media only screen and (max-width: $sm) {
  .slider__prev, .slider__next {
    display: none; } }

/* ======================================================================== */
/* 65. sliderGallery */
/* ======================================================================== */
.slider-gallery__wrapper-slider-nav {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 60; }
.slider-gallery__overlay {
  z-index: 50; }

@media only screen and (max-width: $md) {
  .slider-gallery__overlay {
    display: none; } }

/* ======================================================================== */
/* 66. sliderImages */
/* ======================================================================== */
.slider-images {
  text-align: center;
  position: relative; }
.slider-images__wrapper-slider-nav {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 60; }
.slider-images__slide {
  display: flex;
  align-items: center;
  justify-content: center; }
.slider-images__prev, .slider-images__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 60; }
.slider-images__prev {
  left: 50px; }
.slider-images__next {
  right: 50px; }

@media only screen and (max-width: $xl) {
  .slider-images__prev {
    left: 30px; }
  .slider-images__next {
    right: 30px; } }

/* ======================================================================== */
/* 67. sliderIntroBackgrounds */
/* ======================================================================== */
.slider-intro-backgrounds {
  height: 100%; }

/* ======================================================================== */
/* 68. sliderIntroContent */
/* ======================================================================== */
.slider-intro-content {}
.slider-intro-content__wrapper-button {
  margin-top: 90px;
  overflow: hidden; }
.slider-intro-content__wrapper-slider-nav {
  position: absolute;
  left: 215px;
  bottom: 30px;
  z-index: 60; }

@media only screen and (max-width: $xxl) {
  .slider-intro-content__wrapper-button {
    margin-top: 70px; }
  .slider-intro-content__wrapper-slider-nav {
    left: 50px; } }

@media only screen and (max-width: $xl) {
  .slider-intro-content__wrapper-slider-nav {
    left: 30px; } }

@media only screen and (max-width: $md) {
  .slider-intro-content__wrapper-slider-nav {
    bottom: 15px; } }

@media only screen and (max-width: $sm) {
  .slider-intro-content__wrapper-button {
    margin-top: 50px; } }

/* ======================================================================== */
/* 69. sliderProjectBackgrounds */
/* ======================================================================== */
.slider-project-backgrounds {
  height: 100%; }
.slider-project-backgrounds__slide {
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
        position: relative;
        z-index: -#{$i}; } } }

/* ======================================================================== */
/* 70. sliderProjectContent */
/* ======================================================================== */
.slider-project-content__slide {
  position: relative;
  // overflow: hidden
  display: flex;
  // @for $i from 1 through 10
  //   &:nth-child(#{$i})
  //     .slider-project-content__wrapper-image svg
  //       z-index: -#{$i}
  &.swiper-slide-prev {} }
.slider-project-content__slide-inner {}
.slider-project-content__wrapper-overlay {
  position: absolute;
  top: 0;
  right: 34.5%;
  bottom: 0;
  width: 100%;
  max-width: 820px;
  mix-blend-mode: multiply;
  opacity: 0.95; }
.slider-project-content__diagonal-overlay {
  // &:after
  //   content: ''
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: $accent;
  transform: skewX(-44.25deg); }
.slider-project-content__wrapper-image svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
.slider-project-content__wrapper-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center
  padding-top: 140px;
  padding-bottom: 140px;
  min-height: 820px;
  position: relative;
  h3 {
    font-family: $font-primary;
    font-size: 128px;
    line-height: 1.2;
    letter-spacing: 3.7px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0; }
  p {
    font-size: 20px;
    font-weight: 300; } }
.slider-project-content__wrapper-button {
  position: absolute;
  bottom: 46px;
  left: 15px; }
.slider-project-content__wrapper-slider-nav {
  position: absolute;
  display: inline-block;
  left: 50%;
  bottom: 14px;
  transform: translateX(-50%);
  z-index: 60; }
.slider-project-content__prev, .slider-project-content__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 60; }
.slider-project-content__prev {
  left: 50px; }
.slider-project-content__next {
  right: 50px; }

@media only screen and (max-width: $xxl) {
  .slider-project-content__prev {
    left: 30px; }
  .slider-project-content__next {
    right: 30px; } }

@media only screen and (max-width: $xl) {
  .slider-project-content__wrapper-content {
    h3 {
      font-size: 96px; }
    p {
      font-size: 18px; } }
  .slider-project-content__wrapper-button {
    left: calc(8.33333% + 15px); }
  .slider-project-content__wrapper-content {
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 700px; } }

@media only screen and (max-width: $md) {
  //
  .slider-project-content__wrapper-overlay {
    right: 25%; }
  .slider-project-content__wrapper-content {
    text-align: center;
    h3 {
      font-size: 72px;
      letter-spacing: 2px; }
    p {
      font-size: 16px; } }
  .slider-project-content__wrapper-content {
    min-height: 600px; }
  .slider-project-content__wrapper-button {
    margin-top: 30px;
    position: relative;
    bottom: auto;
    left: auto; } }

@media only screen and (max-width: $sm) {
  .slider-project-content__wrapper-content {
    h3 {
      font-size: 38px; } }
  .slider-project-content__wrapper-overlay {
    right: 0%; }
  .slider-project-content__slide {
    overflow: hidden; }
  .slider-project-content__wrapper-button {
    margin-top: 20px; }
  .slider-project-content__wrapper-content {
    min-height: 500px; } }

/* ======================================================================== */
/* 71. sliderServices */
/* ======================================================================== */
.slider-services {
	h4 {
		font-size: 23px;
		line-height: 36px;
		font-weight: 300;
		font-family: $font-primary;
		margin-top: 1.5em;
		margin-bottom: 1.5em; } }
.slider-services__slide {}
.slider-services__headline {
	width: 100%;
	height: 1px;
	background-color: $light2; }
.slider-services__header {
	padding-top: 20px; }
.slider-services__wrapper-text {
	padding-top: 0px; }


@media only screen and (max-width: $md) {
	.slider-services h4 {
		margin-top: 1em;
		margin-bottom: 1em; } }

/* ======================================================================== */
/* 72. sliderTestimonials */
/* ======================================================================== */
.slider-testimonials {
	padding-top: 190px;
	padding-bottom: 190px; }
.slider-testimonials__slide {
	p {
		margin-top: 0;
		font-weight: 300;
		font-size: 28px;
		line-height: 1.5357; } }
.slider-testimonials__name {
	font-weight: bold; }
.slider-testimonials__wrapper-slider-nav {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	z-index: 100;
	text-align: center; }

@media only screen and (max-width: $xl) {
	.slider-testimonials {
		padding-top: 100px;
		padding-bottom: 100px; }
	.slider-testimonials__slide p {
		font-size: 24px; } }

@media only screen and (max-width: $sm) {
	.slider-testimonials {
		padding-top: 60px;
		padding-bottom: 80px; }
	.slider-testimonials__wrapper-slider-nav {
		bottom: 15px; } }

/* ======================================================================== */
/* 73. social */
/* ======================================================================== */
.social {
  @include reset-ul; }
.social__item {
  display: inline-block;
  margin: 0 6px; }
.social__icon {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 50px !important;
  height: 50px !important;
  font-size: 18px;
  color: $white;
  background-color: $gray2;
  border-radius: 100%;
  &:hover {
    color: $white;
    background-color: $accent; } }

@media only screen and (max-width: $xl) {
  .social__icon {
    width: 40px !important;
    height: 40px !important;
    font-size: 14px; } }

/* ======================================================================== */
/* 74. tabs */
/* ======================================================================== */
.tabs {
  text-align: center;
  position: relative;
  font-family: $font-secondary;
  padding-bottom: 15px; }
.tabs__header {
  margin-bottom: 60px;
  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    font-family: $font-secondary;
    letter-spacing: 1px; } }
.tabs__item {
  cursor: pointer;
  color: $gray2;
  @include trans1;
  h4 {
    margin-top: 0;
    margin-bottom: 0;
    display: inline-block;
    font-size: 16px;
    font-weight: bold; }
  &:hover {
    color: inherit; } }
.tabs__item_active {
  color: inherit;
  .tabs__icon {
    color: $accent;
    @include trans1; } }
.tabs__icon {
  font-size: 50px;
  margin-bottom: 5px; }
.tabs__underline {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background-color: $white;
  width: 0;
  height: 1px; }

@media only screen and (max-width: $md) {
  .tabs__underline {
    display: none; } }

@media only screen and (max-width: $sm) {
  .tabs__item {
    margin-top: 15px;
    margin-bottom: 15px; }
  .tabs__header {
    margin-bottom: 30px; } }

/* ======================================================================== */
/* 75. tags */
/* ======================================================================== */
.tagcloud {}

/* ======================================================================== */
/* 76. typography */
/* ======================================================================== */
h1, h2, h3, h4, h5, h6 {
  font-family: $font-secondary; }
h1 {
  font-size: 60px;
  margin: 0.25em 0;
  line-height: 1.2; }
h2 {
  font-size: 50px;
  margin: 0.5em 0;
  line-height: 1.25; }
h3 {
  font-size: 46px;
  margin: 0.75em 0;
  line-height: 1.3; }
h4 {
  font-size: 30px;
  margin: 1em 0;
  line-height: 1.4; }
h5 {
  font-size: 21px;
  margin: 1em 0; }
h6 {
  font-size: 18px;
  margin: 1em 0; }
p {
  margin-top: 1em;
  margin-bottom: 1em; }
blockquote {
  display: block;
  font-size: 20px;
  line-height: 1.5;
  margin-top: 2em;
  margin-bottom: 2em;
  p {
    margin-top: 0.5em; }
  cite {
    display: block;
    font-family: $font-secondary;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    margin-top: 20px; }
  &:before {
    content: '{';
    @extend .elegant-icons;
    color: $accent;
    font-size: 40px;
    margin-left: -10px; } }


@media only screen and (max-width: $xl) {
  h1 {
    font-size: 46px; }
  h2 {
    font-size: 37px; }
  h3 {
    font-size: 34px; }
  h4 {
    font-size: 25px; }
  h5 {
    font-size: 18px; }
  h6 {
    font-size: 16px; } }

@media only screen and (max-width: $sm) {
  h1 {
    font-size: 42px; }
  h2 {
    font-size: 33px; }
  h3 {
    font-size: 30px; }
  h4 {
    font-size: 22px; }
  h5 {
    font-size: 16px; }
  h6 {
    font-size: 14px; } }

/* ======================================================================== */
/* 77. utilities */
/* ======================================================================== */
.text-left {
  text-align: left !important; }
.text-center {
  text-align: center !important; }
.text-right {
  text-align: right !important; }
.line {}
.row-80 {
  margin-left: -40px;
  margin-right: -40px; }
.overflow {
  position: relative;
  overflow: hidden; }
.overflow__curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50; }
.overflow__content {
  width: 100%;
  height: 100%; }
.position-relative {
  position: relative; }
.color-white {
  color: $white !important; }
.color-accent {
  color: $accent !important; }
.color-black {
  color: $black !important; }
.color-dark {
  color: $dark !important; }
.blurred {
  filter: blur(7px); }
.of-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;'; }
.of-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;'; }
.split-line {
  overflow: hidden; }
.split-word {}
.split-char {}
.elegant-icons {
  font-family: 'ElegantIcons';
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
.hidden {
  opacity: 0;
  visibility: hidden; }

/* ======================================================================== */
/* 78. widget */
/* ======================================================================== */
.widget {
  margin-bottom: 50px; }
.widget_no-margin {
  margin-bottom: 0; }
