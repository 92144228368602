/* ======================================================================== */
/* 3. articleProject */
/* ======================================================================== */
.project__wrapper-category
  margin-bottom: 80px
  span
    display: block
    font-size: 13px
    line-height: 1.538
    font-weight: bold
    text-transform: uppercase
    &:after
      content: ''
      display: block
      width: 48px
      height: 1px
      background-color: $black
      margin-top: 14px
.project__header
  h2
    font-family: $font-secondary
    font-size: 46px
    line-height: 1.304
    margin-top: 0
    margin-bottom: 40px
.project__wrapper-properties
  margin-top: 25px
.project__wrapper-slider
  margin-top: 140px


@media only screen and (max-width: $xxl)
  .project__wrapper-category
    margin-bottom: 40px
  .project__header
    h2
      margin-bottom: 30px
  .project__wrapper-slider
    margin-top: 100px

@media only screen and (max-width: $xl)
  .project__wrapper-category
    margin-bottom: 30px
  .project__header
    h2
      font-size: 34px
      margin-bottom: 20px
  .project__wrapper-slider
    margin-top: 80px

@media only screen and (max-width: $md)
  .project__wrapper-button
    margin-top: 15px
    margin-bottom: 15px

@media only screen and (max-width: $sm)
  .project__wrapper-slider
    margin-top: 60px
