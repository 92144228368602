/* ======================================================================== */
/* 53. sectionMap */
/* ======================================================================== */
.section-map
	z-index: 100
.section-map__wrapper-form
	position: absolute
	right: 50px
	top: -150px
	max-width: 510px
	width: 100%
	padding: 50px 50px 35px
	background-color: $white
	z-index: 100
.section-map__wrapper-info
	position: absolute
	left: 50px
	top: 50%
	max-width: 420px
	width: 100%
	transform: translateY(-50%)
	z-index: 100
	background-color: $accent
	padding: 50px 35px
	.figure-contact
		display: flex
		flex-wrap: wrap
	.figure-contact__item
		flex: 1 1 50%
		margin-bottom: 30px
		padding: 0 15px
	.figure-contact__property
		font-size: 13px
		font-weight: bold
	.figure-contact__value
		font-size: 15px

@media only screen and (max-width: $xxl)
	.section-map__content
		display: flex
		flex-wrap: wrap
	.section-map__wrapper-form
		position: relative
		right: auto
		top: auto
		flex: 1 0 420px
		// padding: 80px 50px 65px
	.section-map__wrapper-map
		width: 100%
		// max-width: calc(100% / 3 * 2)
		max-width: 100%
		flex: 0 1 calc(100% - 420px)
	.section-map__wrapper-info
		position: relative
		left: auto
		top: auto
		width: 100%
		max-width: 100%
		transform: none
	.section-map__wrapper-info
		text-align: center
		.figure-contact
			justify-content: center
		.figure-contact__item
			flex: 0 1 25%
			margin-top: 15px
			margin-bottom: 15px

@media only screen and (max-width: $md)
	.section-map__wrapper-form
		flex: 1 0 100%
		max-width: 100%
	.section-map__wrapper-map
		flex: 1 0 100%

@media only screen and (max-width: $sm)
	.section-map__wrapper-form
		padding: 60px 30px
	.section-map__wrapper-info
		.figure-contact__item
			flex: 1 1 100%
