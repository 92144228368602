/* ======================================================================== */
/* 46. sectionAbout */
/* ======================================================================== */
.section-about__header
	h2
		font-size: 60px
		line-height: 1.2
		margin-bottom: 40px
	p
		&:last-child
			margin-bottom: 0
.section-about__wrapper-subheading
  margin-bottom: 40px
  span
    display: block
    font-size: 13px
    line-height: 1.538
    font-weight: bold
    text-transform: uppercase
    &:after
      content: ''
      display: block
      width: 48px
      height: 1px
      background-color: $black
      margin-top: 14px
.section-about__wrapper-image
  text-align: center

@media only screen and (max-width: $xl)
  .section-about__header
    h2
      font-size: 34px

@media only screen and (max-width: $md)
  .section-about__wrapper-image
    margin-top: 50px
