/* ======================================================================== */
/* 29. grid */
/* ======================================================================== */
.grid
  // width: 100%
.grid_30
  margin: -15px
.grid_5
  margin: -2.5px
.grid__item, .grid__sizer
  display: block
  img
    width: 100%
    position: relative
    z-index: -2
.grid__sizer
  padding: 0 !important
  height: 0px !important
.grid__item_30
  padding: 15px
.grid__item_5
  padding: 2.5px
.grid__item-link
  position: relative
  display: block
  text-decoration: none
  &:hover
    .grid__item-info
      h3
        opacity: 1
        visibility: visible
        transform: translateY(0px)
        &:after
          transform: scaleX(1)
          transition-delay: 100ms
      .grid__item-category
        opacity: 1
        visibility: visible
        transition-delay: 200ms
        transform: translateY(0px)
    .grid__item-overlay
      transform: scaleY(1)
.grid__item-info
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  color: $white
  z-index: 50
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  h3
    position: relative
    font-size: 32px
    font-weight: bold
    text-transform: uppercase
    margin-top: 0
    margin-bottom: 0
    letter-spacing: 0.5px
    +trans1
    opacity: 0
    visibility: hidden
    transform: translateY(40px)
    &:after
      content: ''
      display: block
      width: 100%
      height: 1px
      background-color: $white
      margin-bottom: 30px
      +trans2
      transform: scaleX(0)
.grid__item-category
  font-weight: bold
  text-transform: uppercase
  letter-spacing: 0.5px
  +trans1
  opacity: 0
  visibility: hidden
  transform: translateY(20px)
.grid__item-overlay
  transform: scaleY(0)
  transform-origin: center bottom
  +trans1

@media only screen and (max-width: $xl)
  .grid__item-info
    h3
      font-size: 24px
      &:after
        margin-bottom: 15px
  .grid__item-category
    font-size: 12px
