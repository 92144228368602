/* ======================================================================== */
/* 69. sliderProjectBackgrounds */
/* ======================================================================== */
.slider-project-backgrounds
  height: 100%
.slider-project-backgrounds__slide
  @for $i from 1 through 10
    &:nth-child(#{$i})
        position: relative
        z-index: -#{$i}
